import clsx from "clsx";
import { FC, useState } from "react";
import { useStopwatch } from "react-timer-hook";

import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/providers/ScoresProvider.tsx";
import { generateGrid, getRandomAlphabet } from "~/routes/tangram/utils.tsx";

export interface TrainingBaseViewProps {
  withChrono?: boolean;
}

export const TrainingBaseView: FC<TrainingBaseViewProps> = () => {
  const {
    totalSeconds,
    reset: resetStopwatch,
    pause: pauseStopwatch,
  } = useStopwatch({
    autoStart: true,
  });
  const { saveScore } = useScores();

  const [randomAlphabet] = useState(getRandomAlphabet());
  const [grid, setGrid] = useState(generateGrid(4, randomAlphabet));
  const [highlightedFigure, setHighlightedFigure] = useState("");
  const [showResult, setShowResult] = useState(false);

  const [values, setValues] = useState<Record<string, string>>({});

  const handleOnClickFigure = (name: string) => {
    setHighlightedFigure(name === highlightedFigure ? "" : name);
  };

  const handleShowResult = () => {
    pauseStopwatch();
    setShowResult(true);

    saveScore(Exercise.Tangram, "guess-all", {
      timing: totalSeconds,
      success: grid.figures.every(
        (fig) =>
          grid.items.filter((f) => f.letter === fig.letter).length ===
          parseInt(values[fig.letter] ?? "-1"),
      ),
    });
  };

  const handleReset = () => {
    setValues({});
    setShowResult(false);
    setGrid(generateGrid(4, randomAlphabet));
    resetStopwatch();
  };

  const withGrid = false;

  return (
    <div className="grid grid-col-1 sm:grid-cols-2">
      <div
        className={clsx(
          "mx-auto flex flex-wrap w-80 sm:w-96",
          withGrid && "divide-x divide-y",
        )}
      >
        {grid.items.map(({ Figure, name, letter }, idx) => (
          <Figure
            key={idx}
            onClick={() => handleOnClickFigure(name)}
            highlight={highlightedFigure === name}
            withCaption={showResult ? letter : undefined}
            size="md"
          />
        ))}
      </div>
      <div className="mt-8 flex flex-wrap gap-2 sm:gap-4 justify-center max-w-96 mx-auto">
        {grid.figures.map(({ Figure, name, letter }) => (
          <div key={`figure-${name}`} className="relative">
            <Figure
              size="xs"
              highlight={highlightedFigure === name}
              onClick={() => handleOnClickFigure(name)}
            />
            <div className="relative">
              <Input
                value={values[letter] ?? ""}
                onChange={(evt) =>
                  setValues({
                    ...values,
                    [letter]: evt.currentTarget.value,
                  })
                }
                className={clsx(
                  "w-16 mt-2 mx-auto",
                  showResult &&
                    (grid.items.filter((f) => f.letter === letter).length ===
                    parseInt(values[letter] ?? "-1")
                      ? "bg-green-100"
                      : "bg-red-100 font-semibold"),
                )}
              />
              {showResult && (
                <div className="absolute bottom-0 left-0 right-0 text-right h-9 pr-2.5 pt-2 font-mono text-sm">
                  {grid.items.filter((f) => f.letter === letter).length}
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="w-full">
          {showResult ? (
            <div className="text-center">
              <Button className="w-full" onClick={handleReset}>
                Suivant
              </Button>
              <p className="text-xs mt-2 text-slate-500">
                ({totalSeconds} sec)
              </p>
            </div>
          ) : (
            <Button className="w-full" onClick={handleShowResult}>
              Correction
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

import clsx from "clsx";
import { useAtom } from "jotai";
import { random } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";

import { HeaderWithMode } from "~/components/HeaderWithMode.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import { SpeedSlider } from "~/components/SpeedSlider.tsx";
import { StartScreen } from "~/components/StartScreen.tsx";
import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/providers/ScoresProvider.tsx";
import {
  memoryInverseModeAtom,
  memorySpeedAtom,
} from "~/routes/memory/atoms.ts";
import { NumberDisplay } from "~/routes/memory/NumberDisplay.tsx";
import { randomIntArrayInRange, reverseString } from "~/routes/memory/utils.ts";

export const Memory: FC = () => {
  const { saveScore } = useScores();

  const [mode, setMode] = useAtom(memoryInverseModeAtom);
  const [speed, setSpeed] = useAtom(memorySpeedAtom);

  const [numbers, setNumbers] = useState(
    randomIntArrayInRange(
      0,
      9,
      mode === "random" ? random(3, 8) : parseInt(mode),
    ),
  );

  const [hasStarted, setHasStarted] = useState(false);
  const [isDisplayComplete, setIsDisplayComplete] = useState(false);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [inputValue, setInputValue] = useState("");

  const handleComplete = () => {
    setIsDisplayComplete(true);
  };

  const handleCheckResult = useCallback(() => {
    setIsCorrect(reverseString(numbers.join("")) === inputValue);

    saveScore(Exercise.Memory, `inverse:${mode}:${speed}`, {
      success: reverseString(numbers.join("")) === inputValue,
      timing: 0,
    });
  }, [inputValue, mode, numbers, saveScore, speed]);

  const handleStart = () => {
    setHasStarted(true);
    setIsCorrect(null);
    setInputValue("");
  };

  const handleRestart = useCallback(() => {
    setNumbers(
      randomIntArrayInRange(
        0,
        9,
        mode === "random" ? random(3, 8) : parseInt(mode),
      ),
    );
    setIsDisplayComplete(false);
    setIsCorrect(null);
    setInputValue("");
  }, [mode]);

  useEffect(() => {
    setHasStarted(false);
    handleRestart();
  }, [handleRestart, mode]);

  useEffect(() => {
    if (inputValue.length === numbers.length) {
      handleCheckResult();
    }
  }, [handleCheckResult, inputValue.length, numbers.length]);

  return (
    <Wrapper
      header={
        <HeaderWithMode
          actions={
            <ScoreButton
              exercise={Exercise.Memory}
              label={`Memory Inversé (${mode} chiffres, ${speed} sec)`}
              variant={`inverse:${mode}:${speed}`}
              withTimings={false}
              filter={() => true}
            />
          }
          mode={
            <Select value={mode} onValueChange={setMode}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner un mode…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="3">3 chiffres</SelectItem>
                <SelectItem value="4">4 chiffres</SelectItem>
                <SelectItem value="5">5 chiffres</SelectItem>
                <SelectItem value="6">6 chiffres</SelectItem>
                <SelectItem value="7">7 chiffres</SelectItem>
                <SelectItem value="8">8 chiffres</SelectItem>
                <SelectItem value="9">9 chiffres</SelectItem>
                <SelectItem value="10">10 chiffres</SelectItem>
                <SelectItem value="random">
                  Aléatoire (3 - 8 chiffres)
                </SelectItem>
              </SelectContent>
            </Select>
          }
        />
      }
    >
      <StartScreen
        started={hasStarted}
        onStart={handleStart}
        description="Une série de chiffres va s'afficher. Mémorisez chaque chiffre puis, saisissez-les dans l'ordre inverse dans le champs."
        configuration={<SpeedSlider value={speed} onChange={setSpeed} />}
      >
        <div className="flex flex-col items-center justify-center">
          {!isDisplayComplete ? (
            <NumberDisplay
              numbers={numbers}
              speed={speed}
              onComplete={handleComplete}
            />
          ) : (
            <div className="max-w-screen-lg">
              <Input
                className={clsx(
                  "text-3xl py-2 w-60 tabular-nums text-center",
                  isCorrect === true && "bg-green-100 text-green-500",
                  isCorrect === false && "bg-red-100 text-red-500",
                )}
                value={inputValue}
                onChange={(evt) => setInputValue(evt.currentTarget.value)}
                onKeyUp={(evt) => {
                  if (evt.key === "Enter") {
                    handleRestart();
                  }
                }}
                autoFocus={true}
              />
              {isCorrect === null ? (
                <Button
                  className="w-full mt-3"
                  onClick={handleCheckResult}
                  type="button"
                >
                  Valider
                </Button>
              ) : (
                <div className="text-center mt-2 ">
                  {isCorrect ? (
                    <p className="font-medium text-green-500">Correct</p>
                  ) : (
                    <p className="font-medium text-red-500">
                      Faux ! ({reverseString(numbers.join(""))})
                    </p>
                  )}
                  <button
                    type="button"
                    className="text-xs underline hover:no-underline mt-4"
                    onClick={handleRestart}
                  >
                    Suivant (ou touche Entrée)
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </StartScreen>
    </Wrapper>
  );
};

import { FC, ReactNode } from "react";

interface HeaderWithModeProps {
  actions?: ReactNode;
  mode: ReactNode;
}

export const HeaderWithMode: FC<HeaderWithModeProps> = ({ actions, mode }) => (
  <div className="flex w-full items-center justify-between">
    <div>{mode}</div>
    <div>{actions}</div>
  </div>
);

import { clsx } from "clsx";
import { FC, ReactNode } from "react";

interface AdminBlockProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  className?: string;
}

export const AdminBlock: FC<AdminBlockProps> = ({
  title,
  subtitle,
  children,
  className,
}) => (
  <div className={clsx("border rounded", className)}>
    <h2 className="font-medium sm:text-lg px-3 sm:px-4 py-1 sm:py-1.5 border-b bg-slate-50">
      {title}
      {subtitle && (
        <span className="block text-xs uppercase font-light tracking-wide text-slate-500">
          {subtitle}
        </span>
      )}
    </h2>
    {children}
  </div>
);

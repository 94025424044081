import { clsx } from "clsx";
import { differenceInHours, formatRelative } from "date-fns";
import {
  PlugIcon,
  RefreshCwIcon,
  RefreshCwOffIcon,
  UploadIcon,
} from "lucide-react";
import { FC, ReactNode, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { toast } from "sonner";

import { LinkDonate } from "~/components/LinkDonate.tsx";
import {
  SyncStatusDot,
  SyncStatusDotProps,
} from "~/components/SyncStatusDot.tsx";
import { Button } from "~/components/ui/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/ui/tooltip.tsx";
import { PilotestAutoSyncModal } from "~/routes/suivi/components/PilotestAutoSyncModal.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useImports } from "~/routes/suivi/providers/ImportsProvider.tsx";
import { usePilotestCredentials } from "~/routes/suivi/providers/PilotestCredentialsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { handleCsvUpload } from "~/routes/suivi/utils/handleCsvUpload.ts";
import { handleJsonUpload } from "~/routes/suivi/utils/handleJsonUpload.ts";

interface SyncStatusBlockProps {
  color?: SyncStatusDotProps["color"];
  wrapperClassName?: string;
  textClassName: string;
  children: ReactNode;
}

const SyncStatusBlock: FC<SyncStatusBlockProps> = ({
  color,
  wrapperClassName,
  textClassName,
  children,
}) => (
  <div className={clsx("flex items-center gap-2", wrapperClassName)}>
    <SyncStatusDot color={color} />
    <p className={clsx("text-[10px] sm:text-sm font-medium", textClassName)}>
      {children}
    </p>
  </div>
);

export const SyncStatus = () => {
  const navigate = useNavigate();

  const { imports, canImportAttempts } = useImports();
  const { settings, saveSettings } = useSettings();
  const { isSyncing, saveAttempts, refreshAttempts, deleteAttempts } =
    useAttempts();
  const { hasPilotestAutoSyncEnabled, disablePilotestAutoSync } =
    usePilotestCredentials();

  const [showPilotestSyncModal, setShowPilotestSyncModal] = useState(false);

  const refFileInput = useRef<HTMLInputElement | null>(null);

  const lastPilotestImport = imports.find((imp) => imp.provider === "pilotest");
  const lastPilotestImportDifference =
    lastPilotestImport && differenceInHours(new Date(), lastPilotestImport.at);

  const handleDisablePilotestSync = () => {
    if (
      confirm(
        "Êtes-vous sur de vouloir désactiver l'import automatique Pilotest ? Vos résultats Pilotest seront supprimés et vous devrez importer manuellement vos résultats par fichier .csv.",
      )
    ) {
      disablePilotestAutoSync();
      deleteAttempts("pilotest");
    }
  };

  const handleEnableEplTest = async () => {
    const eplApiKey = prompt(
      "Veuillez saisir votre clé API EPLtest pour activer la synchronisation de vos résultats EPLtest.\n\nVous pouvez récupérer votre clé depuis le site EPLtest : \nhttps://epltest.fr/fr/account/public_api",
    );

    if (!eplApiKey) {
      return;
    }

    saveSettings({ epl_api_key: eplApiKey }).then(() =>
      navigate("/suivi/stats?forceSync=true"),
    );
  };

  const handleDisableEplTest = () => {
    if (
      confirm(
        "Êtes-vous sur de vouloir désactiver la synchronisation EPLtest ? Vos futurs résultats ne seront plus synchronisés.",
      )
    ) {
      saveSettings({ epl_api_key: null }).then(() => {
        if (
          confirm(
            "Souhaitez-vous supprimer les résultats EPLtest importés jusqu'ici ?",
          )
        ) {
          return deleteAttempts("eplTest");
        }
      });
    }
  };

  useEffectOnce(() => {
    const pilotestImports = imports.filter(
      (imp) => imp.provider === "pilotest",
    );

    if (
      pilotestImports.some((imp) => imp.attempts_added > 0) &&
      pilotestImports.every((imp) => imp.source === "auto_sync") &&
      !hasPilotestAutoSyncEnabled
    ) {
      toast(
        <div className="mt-1 w-[496px]">
          <p className="font-semibold text-lg text-blue-600 inline-flex items-center gap-1.5">
            <PlugIcon size={20} />
            <span>Ré-activer la synchronisation Pilotest</span>
          </p>
          <p className="text-sm text-slate-500 mt-2 font-medium">
            Vous avez activé la synchronisation automatique de vos résultats
            Pilotest depuis un autre appareil et/ou navigateur.
          </p>
          <p className="text-sm text-slate-500 mt-2">
            Pour continuer à synchroniser vos résultats Pilotest depuis ce
            nouvel appareil ou navigateur, vous devez saisir vos identifiants à
            nouveau.
          </p>
          <p className="mt-4 mb-1">
            <Button
              className="w-full"
              onClick={() => {
                toast.dismiss("global:resyncPilotest");
                setShowPilotestSyncModal(true);
              }}
            >
              Ré-activer la synchronisation Pilotest
            </Button>
          </p>
        </div>,
        {
          id: "global:resyncPilotest",
          position: "top-center",
          className: "w-fit",
          duration: Infinity,
        },
      );
    }
  });

  if (!canImportAttempts) {
    return (
      <div className="hidden sm:flex items-center justify-end gap-6">
        <div className="text-right">
          <p className="text-sm font-semibold text-orange-500">
            Vous avez déjà réalisé 5 imports aujourd'hui !
          </p>
          <p className="text-xs text-slate-400">
            Nous vous invitons à{" "}
            <LinkDonate wrapperClassname="font-semibold text-slate-500">
              participer aux frais
            </LinkDonate>{" "}
            ou à revenir demain
            <br />
            pour importer de nouveaux résultats dans KD Tools.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="hidden sm:flex items-center justify-end gap-6">
        {/*Pilotest*/}
        <div className="flex items-center">
          {hasPilotestAutoSyncEnabled ? (
            isSyncing ? (
              <SyncStatusBlock color="blue" textClassName="text-sky-500">
                Synchronisation Pilotest en cours…
              </SyncStatusBlock>
            ) : (
              <SyncStatusBlock color="green" textClassName="text-green-500">
                Synchronisation Pilotest OK
              </SyncStatusBlock>
            )
          ) : lastPilotestImport !== undefined &&
            lastPilotestImportDifference !== undefined ? (
            <SyncStatusBlock
              color={lastPilotestImportDifference > 24 ? "orange" : "green"}
              textClassName={
                lastPilotestImportDifference > 24
                  ? "text-orange-500"
                  : "text-green-500"
              }
            >
              Dernier import Pilotest{" "}
              {formatRelative(lastPilotestImport.at, new Date())}
            </SyncStatusBlock>
          ) : (
            <SyncStatusBlock textClassName="text-slate-300">
              Synchronisation Pilotest désactivée
            </SyncStatusBlock>
          )}
          <div className="ml-3 hidden sm:flex items-center gap-2">
            {!hasPilotestAutoSyncEnabled && (
              <>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Button
                      size="xs"
                      onClick={() => setShowPilotestSyncModal(true)}
                    >
                      <RefreshCwIcon className="w-3.5 h-3.5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    Activer la synchronisation automatique Pilotest sur ce
                    navigateur
                  </TooltipContent>
                </Tooltip>
                {!imports.some(
                  (imp) =>
                    imp.source === "auto_sync" &&
                    imp.provider === "pilotest" &&
                    imp.attempts_added > 0,
                ) && (
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Button
                        size="xs"
                        variant="outline"
                        onClick={() => {
                          refFileInput.current?.click();
                        }}
                      >
                        <UploadIcon className="w-3.5 h-3.5" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <a
                        id="btn-download-pilotest-csv"
                        href="https://www.pilotest.com/fr/results.csv"
                        rel="noreferrer"
                        className="underline hover:no-underline"
                        target="_blank"
                      >
                        Télécharger
                      </a>{" "}
                      et importer un nouvel export Pilotest
                    </TooltipContent>
                  </Tooltip>
                )}
              </>
            )}
            {hasPilotestAutoSyncEnabled && (
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button
                    size="xs"
                    variant="outline-destructive"
                    onClick={handleDisablePilotestSync}
                    disabled={isSyncing}
                  >
                    <RefreshCwOffIcon className="w-3.5 h-3.5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Supprimer la connexion Pilotest</TooltipContent>
              </Tooltip>
            )}
          </div>
        </div>
        {/* EPLtest */}
        <div className="flex items-center">
          {settings?.epl_api_key && isSyncing ? (
            <SyncStatusBlock color="blue" textClassName="text-sky-500">
              Synchronisation EPLtest en cours…
            </SyncStatusBlock>
          ) : (
            <div className="flex items-center">
              {settings?.epl_api_key ? (
                <SyncStatusBlock color="green" textClassName="text-green-500">
                  Synchronisation EPLtest OK
                </SyncStatusBlock>
              ) : (
                <SyncStatusBlock textClassName="text-slate-300">
                  Synchronisation EPLtest désactivée
                </SyncStatusBlock>
              )}
            </div>
          )}
          <div className="ml-3 hidden sm:flex items-center gap-2">
            {settings?.epl_api_key ? (
              <>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Button
                      size="xs"
                      variant="outline-destructive"
                      onClick={handleDisableEplTest}
                      disabled={isSyncing}
                    >
                      <RefreshCwOffIcon className="w-3.5 h-3.5" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    Supprimer la connexion EPLtest
                  </TooltipContent>
                </Tooltip>
              </>
            ) : (
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button size="xs" onClick={handleEnableEplTest}>
                    <RefreshCwIcon className="w-3.5 h-3.5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Activer la synchronisation EPLtest
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <input
        ref={refFileInput}
        id="csvFile"
        type="file"
        name="csvFile"
        accept=".csv"
        className="hidden"
        onChange={(evt) =>
          handleCsvUpload(evt, (newAttempts) =>
            saveAttempts("input_csv", "pilotest", newAttempts ?? []).then(
              refreshAttempts,
            ),
          )
        }
      />
      <input
        id="jsonFile"
        type="file"
        name="jsonFile"
        accept=".json"
        className="hidden"
        onChange={(evt) =>
          handleJsonUpload(evt, (newAttempts) =>
            saveAttempts("tamper_json", "pilotest", newAttempts ?? []).then(
              refreshAttempts,
            ),
          )
        }
      />
      <PilotestAutoSyncModal
        open={showPilotestSyncModal}
        onDismiss={() => setShowPilotestSyncModal(false)}
      />
    </>
  );
};

import { clsx } from "clsx";
import { BlocksIcon, CloudIcon, FileInputIcon } from "lucide-react";
import { FC } from "react";

import { Database } from "~/supabase.types.ts";

interface AdminImportProps {
  source: Database["public"]["Enums"]["import_source"];
  className?: string;
}

export const AdminImport: FC<AdminImportProps> = ({ source, className }) => {
  switch (source) {
    case "auto_sync":
      return (
        <span
          className={clsx(
            "text-green-400 text-xs uppercase font-medium flex gap-1.5 items-center",
            className,
          )}
        >
          <CloudIcon className="w-4 h-4 shrink-0" />
          Auto
        </span>
      );
    case "input_csv":
      return (
        <span
          className={clsx(
            "text-blue-500 text-xs uppercase font-medium flex gap-1.5 items-center",
            className,
          )}
        >
          <FileInputIcon className="w-4 h-4 shrink-0" />
          CSV
        </span>
      );
    case "tamper_csv":
      return (
        <span
          className={clsx(
            "text-blue-400 text-xs uppercase font-medium flex gap-1.5 items-center",
            className,
          )}
        >
          <BlocksIcon className="w-4 h-4 shrink-0" />
          Tamper CSV
        </span>
      );
    case "tamper_json":
      return (
        <span
          className={clsx(
            "text-blue-400 text-xs uppercase font-medium flex gap-1.5 items-center",
            className,
          )}
        >
          <BlocksIcon className="w-4 h-4 shrink-0" />
          Tamper JSON
        </span>
      );
    default:
      return null;
  }
};

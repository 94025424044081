import { FC } from "react";

import FadeContent from "~/components/blocks/Animations/FadeContent/FadeContent.tsx";
import SplitText from "~/components/blocks/TextAnimations/SplitText/SplitText.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Favorites } from "~/routes/root/components/Favorites.tsx";
import { SnowFall } from "~/routes/root/components/SnowFall.tsx";
import { getDailyRandomItem } from "~/routes/root/utils.ts";

export const Root: FC = () => {
  const {
    user: { profile },
  } = useCurrentUser();

  return (
    <>
      <SnowFall />
      <div className="flex flex-col items-center justify-between h-full">
        <div className="flex flex-col justify-center flex-1 w-full">
          <div className="flex flex-col items-center justify-center">
            <FadeContent delay={500} duration={2500} className="text-6xl">
              {getDailyRandomItem(["👋", "✈️", "☀️", "🛫", "🌍", "🗺️", "🤞"])}
            </FadeContent>
            <p className="text-2xl font-medium mt-6 text-slate-600">
              <SplitText
                text={`Bienvenue, ${profile.discord_username ?? profile.email ?? ""} !`}
                delay={25}
                animationFrom={{
                  opacity: 0,
                  transform: "translate3d(0,50px,0)",
                }}
                animationTo={{ opacity: 1, transform: "translate3d(0,0,0)" }}
                threshold={0.2}
                rootMargin="-50px"
              />
            </p>
            <FadeContent
              duration={2000}
              delay={1000}
              className="text-center text-slate-400 text-sm mt-1 w-10/12 sm:w-full"
            >
              <p>
                Utilisez le menu de gauche pour naviguer entre les différents
                outils.
              </p>
            </FadeContent>
          </div>
          <FadeContent duration={2000} delay={1000}>
            <Favorites />
          </FadeContent>
        </div>
        {/*<Footer />*/}
      </div>
    </>
  );
};

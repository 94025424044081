import React, { FC } from "react";
import {
  Area,
  AreaChart,
  ReferenceDot,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import colors from "tailwindcss/colors";

import { Stanine } from "~/routes/suivi/types/Stanine.ts";
import { getInterpolatedY } from "~/routes/suivi/utils/getInterpolatedY.ts";

const STANINES: {
  x: number;
  y: number;
}[] = [
  { x: 0, y: 3 },
  { x: 1, y: 4 },
  { x: 2, y: 7 },
  { x: 3, y: 12 },
  { x: 4, y: 17 },
  { x: 5, y: 20 },
  { x: 6, y: 17 },
  { x: 7, y: 12 },
  { x: 8, y: 7 },
  { x: 9, y: 4 },
  { x: 10, y: 3 },
];

interface StanineSegment {
  x1: number;
  x2: number;
  backgroundColor: string;
  textColor: string;
}

const STANINE_SEGMENTS: StanineSegment[] = [
  {
    x1: 1,
    x2: 2,
    textColor: colors.red[600],
    backgroundColor: colors.red[400],
  },
  {
    x1: 2,
    x2: 3,
    textColor: colors.orange[600],
    backgroundColor: colors.orange[400],
  },
  {
    x1: 3,
    x2: 4,
    textColor: colors.amber[600],
    backgroundColor: colors.amber[400],
  },
  {
    x1: 4,
    x2: 5,
    textColor: colors.yellow[600],
    backgroundColor: colors.yellow[400],
  },
  {
    x1: 5,
    x2: 6,
    textColor: colors.lime[600],
    backgroundColor: colors.lime[400],
  },
  {
    x1: 6,
    x2: 7,
    textColor: colors.green[600],
    backgroundColor: colors.green[400],
  },
  {
    x1: 7,
    x2: 8,
    textColor: colors.emerald[600],
    backgroundColor: colors.emerald[400],
  },
  {
    x1: 8,
    x2: 9,
    textColor: colors.sky[600],
    backgroundColor: colors.sky[400],
  },
  {
    x1: 9,
    x2: 10,
    textColor: colors.blue[600],
    backgroundColor: colors.blue[400],
  },
];

interface StanineCurveProps {
  stanines?: Stanine;
  highlight?: number;
  height?: number;
}

export const StanineCurve: FC<StanineCurveProps> = ({
  stanines,
  highlight,
  height = 292,
}) => (
  <ResponsiveContainer width="100%" height={height}>
    <AreaChart margin={{ top: 26 }} data={STANINES}>
      <defs>
        <linearGradient id="splitColor" x1="0" y1="0" x2="1" y2="0">
          {STANINE_SEGMENTS.map((seg, i) => {
            const offsetStart = (seg.x1 / STANINES.length) * 100;
            const offsetEnd = (seg.x2 / STANINES.length) * 100;

            return (
              <React.Fragment key={`segment-${i}`}>
                <stop
                  offset={`${offsetStart}%`}
                  stopColor={seg.backgroundColor}
                  stopOpacity={seg.x1 === highlight ? 1 : 0.4}
                />
                <stop
                  offset={`${offsetEnd}%`}
                  stopColor={seg.backgroundColor}
                  stopOpacity={seg.x1 === highlight ? 1 : 0.4}
                />
              </React.Fragment>
            );
          })}
        </linearGradient>
      </defs>

      <XAxis
        dataKey="x"
        type="number"
        domain={["dataMin", "dataMax"]}
        hide={true}
      />
      <YAxis domain={[0, "dataMax"]} hide={true} />

      <Area
        type="natural"
        dataKey="y"
        stroke={colors.slate[300]}
        strokeWidth={1}
        fill="url(#splitColor)"
        baseValue={0}
        isAnimationActive={false}
      />

      {/* To display Stanine Numbers in each Area… */}
      {STANINE_SEGMENTS.map((seg, i) => (
        <ReferenceDot
          key={`reference-dot-1-${i}`}
          x={(seg.x1 + seg.x2) / 2.2}
          y={seg.x1 === highlight ? 0.25 : 0.75}
          r={0}
          stroke="none"
          fill="transparent"
          label={{
            value: seg.x1,
            position: "top",
            fill: seg.textColor,
            fontSize: seg.x1 === highlight ? 18 : 12,
            fontWeight: seg.x1 === highlight ? 600 : 400,
          }}
        />
      ))}

      {/* To display Percentages between Stanines… */}
      {stanines &&
        STANINE_SEGMENTS.map(
          (seg, i) =>
            i > 0 && (
              <ReferenceDot
                key={`reference-dot-2-${i}`}
                x={(seg.x1 + seg.x2) / 2.2 - 0.45}
                y={getInterpolatedY(STANINES, (seg.x1 + seg.x2) / 2.2 - 0.45)}
                r={0}
                stroke="none"
                fill="transparent"
                label={{
                  // @ts-expect-error Dynamically built…
                  value: `${Math.trunc(stanines[`s${i}_upper_bound`])}%`,
                  position: "top",
                  fill: seg.textColor,
                  fontSize: 11,
                  fontWeight: 500,
                  dy: -12,
                }}
              />
            ),
        )}
    </AreaChart>
  </ResponsiveContainer>
);

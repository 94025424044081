import { clsx } from "clsx";
import { BotIcon, MailIcon } from "lucide-react";
import { FC } from "react";
import { Link } from "react-router-dom";

interface AdminUserProps {
  user_id: string;
  email: string;
  discord_username: string | null;
  last_donation_at?: string | null;
  className?: string;
  size?: "md" | "lg";
}

export const AdminUser: FC<AdminUserProps> = ({
  user_id,
  email,
  discord_username,
  last_donation_at,
  className,
  size = "md",
}) => {
  if (discord_username === null) {
    return (
      <Link
        to={`/jarvis?uid=${user_id}`}
        className={clsx(
          "flex gap-2 items-center",
          "cursor-pointer hover:underline underline-offset-2",
          className,
        )}
      >
        <MailIcon
          className={clsx(
            "text-sky-300 shrink-0",
            size === "md" ? "w-4 h-4" : "w-5 h-5",
          )}
        />{" "}
        <span
          className={clsx("truncate", last_donation_at && "text-amber-500")}
        >
          {email}
        </span>
      </Link>
    );
  }

  return (
    <Link
      to={`/jarvis?uid=${user_id}`}
      className={clsx(
        "flex gap-2 items-center",
        "cursor-pointer hover:underline underline-offset-2",
        className,
      )}
    >
      <BotIcon
        className={clsx(
          "text-[#5865F2] shrink-0",
          size === "md" ? "w-4 h-4" : "w-5 h-5",
        )}
      />{" "}
      <span className={clsx("truncate", last_donation_at && "text-amber-500")}>
        {discord_username}
      </span>
    </Link>
  );
};

import {
  Binary,
  BookA,
  Boxes,
  Brackets,
  Calculator,
  EarthIcon,
  Grid2x2X,
  Hash,
  Lightbulb,
  Shapes,
  SquareStack,
  TrendingUp,
  Undo2,
  Watch,
} from "lucide-react";
import { createBrowserRouter, redirect, RouteObject } from "react-router-dom";

import Layout from "~/components/Layout.tsx";
import { Role } from "~/role.ts";
import { Admin } from "~/routes/admin/Admin.tsx";
import { Alphabet } from "~/routes/alphabet";
import { Angles } from "~/routes/angles";
import { BoitesMots } from "~/routes/boites-mots";
import { Calculs } from "~/routes/calculs";
import { Cm1 } from "~/routes/cm1";
import { Cm2 } from "~/routes/cm2";
import { Liens } from "~/routes/liens";
import { Mathematiques } from "~/routes/mathematiques";
import { Memory } from "~/routes/memory";
import { MemoryCalculs } from "~/routes/memory-calculs";
import { NombresPremiers } from "~/routes/nombres-premiers";
import { Quizlet } from "~/routes/quizlet";
import { Root } from "~/routes/root";
import { Donate } from "~/routes/root/Donate.tsx";
import { ErrorPage } from "~/routes/root/ErrorPage.tsx";
import { LoginPage } from "~/routes/root/LoginPage.tsx";
import { Privacy } from "~/routes/root/Privacy.tsx";
import { Suivi } from "~/routes/suivi";
import { OnboardingPage } from "~/routes/suivi/OnboardingPage.tsx";
import { ResetPage } from "~/routes/suivi/ResetPage.tsx";
import { StatsPage } from "~/routes/suivi/StatsPage.tsx";
import { Tangram } from "~/routes/tangram";
import { RouteObjectWithHandle } from "~/types/RouteHandle.ts";

const rootRoutes: RouteObjectWithHandle[] = [
  {
    path: "/suivi",
    element: <Suivi />,
    handle: { title: "Suivi Pilotest & EPLtest", icon: TrendingUp },
    children: [
      {
        index: true,
        loader: () => redirect("/suivi/stats"),
      },
      {
        path: "onboarding",
        element: <OnboardingPage />,
        handle: { title: "Suivi Pilotest & EPLtest", icon: TrendingUp },
      },
      {
        path: "stats",
        element: <StatsPage />,
        handle: { title: "Suivi Pilotest & EPLtest", icon: TrendingUp },
      },
      {
        path: "reset",
        element: <ResetPage />,
        handle: { title: "Suivi Pilotest & EPLtest", icon: TrendingUp },
      },
    ],
  },
  {
    path: "/quizlet",
    element: <Quizlet />,
    handle: {
      title: "Culture G",
      icon: Lightbulb,
    },
  },
];

const adminRoutes: RouteObjectWithHandle[] = [
  {
    path: "/jarvis",
    element: <Admin />,
    handle: { title: "Admin", icon: EarthIcon, requireRole: Role.Admin },
  },
];

export const psy0Routes: RouteObjectWithHandle[] = [
  {
    path: "/alphabet",
    element: <Alphabet />,
    handle: {
      title: "Alphabet",
      icon: BookA,
    },
  },
  {
    path: "/nombres-premiers",
    element: <NombresPremiers />,
    handle: {
      title: "Nombres Premiers",
      icon: Hash,
    },
  },
  {
    path: "/calculs",
    element: <Calculs />,
    handle: {
      title: "Calculs",
      icon: Grid2x2X,
    },
  },
  {
    path: "/boites-mots",
    element: <BoitesMots />,
    handle: {
      title: "Boites à Mots",
      icon: Boxes,
    },
  },
];

export const psy1Routes: RouteObjectWithHandle[] = [
  {
    path: "/mathematiques",
    element: <Mathematiques />,
    handle: {
      title: "Mathématiques",
      icon: Calculator,
    },
  },
  {
    path: "/cm1",
    element: <Cm1 />,
    handle: {
      title: "CM1",
      icon: Binary,
    },
  },
  {
    path: "/cm2",
    element: <Cm2 />,
    handle: {
      title: "CM2",
      icon: Brackets,
      requireRole: Role.CM2,
    },
  },
  {
    path: "/angles",
    element: <Angles />,
    handle: {
      title: "Angles",
      icon: Watch,
      requireRole: Role.Angles,
    },
  },
  {
    path: "/memory-inverse",
    element: <Memory />,
    handle: {
      title: "Memory Inversé",
      icon: Undo2,
      requireRole: Role.Memory,
    },
  },
  {
    path: "/memory-back-calculs",
    element: <MemoryCalculs />,
    handle: {
      title: "Memory Calculs",
      icon: SquareStack,
    },
  },
  {
    path: "/tangram",
    element: <Tangram />,
    handle: {
      title: "Tangram",
      icon: Shapes,
      requireRole: Role.Tangram,
    },
  },
];

const miscRoutes: RouteObjectWithHandle[] = [
  {
    path: "/liens",
    element: <Liens />,
    handle: { title: "Liens" },
  },
  {
    path: "/privacy",
    element: <Privacy />,
    handle: { title: "Politique de confidentalité" },
  },
  { path: "/donate/thank-you", element: <Donate />, handle: {} },
];

export const allRoutes = [
  ...rootRoutes,
  ...psy0Routes,
  ...psy1Routes,
  ...miscRoutes,
  ...adminRoutes,
];

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    handle: { title: "KD Tools" },
    children: [
      {
        path: "/",
        element: <Root />,
        handle: { title: "KD Tools" },
      },
      ...allRoutes,
    ],
  },
  { path: "/login", element: <LoginPage /> },
];

const router = createBrowserRouter(routes);

export default router;

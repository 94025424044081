import { clamp, take, times } from "lodash";
import { TrendingUp } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useScores } from "~/providers/ScoresProvider.tsx";
import { allRoutes } from "~/router.tsx";

const FavoriteBlock = ({
  Icon,
  name,
  onClick,
}: {
  Icon: React.ElementType;
  name: string;
  onClick: () => void;
}) => (
  <div
    className="border rounded px-4 h-10 sm:h-12 sm:px-5 font-medium text-slate-600 hover:cursor-pointer hover:shadow-lg hover:shadow-slate-100 hover:bg-slate-50 flex items-center gap-4"
    onClick={onClick}
  >
    <Icon />
    <span className="sm:text-lg">{name}</span>
  </div>
);

const FavoritePlaceholder = () => (
  <div className="border border-dashed rounded h-10 sm:h-12 px-3 sm:px-6 text-slate-400  flex items-center gap-4"></div>
);

export const Favorites: FC = () => {
  const navigate = useNavigate();

  const { getExercisesTaken } = useScores();

  const [favoriteTools, setFavoriteTools] = useState<string[]>([]);

  useEffect(() => {
    getExercisesTaken().then(setFavoriteTools);
  }, []);

  return (
    <div className="w-full sm:w-7/12 sm:mx-auto mt-4 sm:mt-20">
      <p className="text-xs text-slate-400 uppercase tracking-wide">
        Outils que vous utilisez le plus :
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-3 mt-1 sm:mt-2">
        <FavoriteBlock
          Icon={TrendingUp}
          name="Suivi"
          onClick={() => navigate("/suivi")}
        />

        {times(clamp(5 - favoriteTools.length, 0, 5), (i) => (
          <FavoritePlaceholder key={`placeholder-${i}`} />
        ))}

        {take(favoriteTools, 5).map((tool) => {
          const matchingRoute = allRoutes.find((route) =>
            route.path?.includes(tool),
          );

          if (
            !matchingRoute ||
            !matchingRoute.path ||
            !matchingRoute.handle.icon ||
            !matchingRoute.handle.title
          ) {
            return null;
          }

          return (
            <FavoriteBlock
              key={`tool-${tool}`}
              Icon={matchingRoute.handle.icon}
              name={matchingRoute.handle.title}
              onClick={() => navigate(matchingRoute.path!)}
            />
          );
        })}
      </div>
    </div>
  );
};

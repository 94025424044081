import { FC, useState } from "react";

import { HeaderWithMode } from "~/components/HeaderWithMode.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { DistanceView } from "~/routes/cm2/DistanceView.tsx";

export type CM2_APP_MODE = "distance" | "distanceModulo";

export const Cm2: FC = () => {
  const [mode, setMode] = useState<CM2_APP_MODE>("distance");

  return (
    <Wrapper
      header={
        <HeaderWithMode
          actions={
            <ScoreButton
              exercise={Exercise.CM2}
              label="CM2"
              variant={mode}
              filter={() => true}
            />
          }
          mode={
            <Select
              value={mode}
              onValueChange={(val) => setMode(val as CM2_APP_MODE)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner un mode…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="distance">Distance</SelectItem>
              </SelectContent>
            </Select>
          }
        />
      }
    >
      <DistanceView mode={mode} />
    </Wrapper>
  );
};

export const SUIVI_SELECTIONS: Record<string, string> = {
  "main:cadets_af_to": "Cadets Air France – Transavia",
  "main:pro_af_to": "Pro Air France – Transavia",
  "main:pro_hop": "Pro HOP!",
  "main:cadets_ezy": "Cadets easyJet",
  "main:cadets_luxair": "Cadets Luxair",
  "main:cadets_ryr": "Cadets Ryanair",
  "main:epl": "EPL (S/U/P)",
  "main:cut_e": "Cut-E",
  other: "Autre…",
};

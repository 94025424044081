import {
  addDays,
  addMonths,
  addWeeks,
  format,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { FC, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import colors from "tailwindcss/colors";

import { Button } from "~/components/ui/button.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { useAttempts } from "~/routes/suivi/providers/AttemptsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";
import { ExerciseAttempt } from "~/routes/suivi/types/ExerciseAttempt.ts";

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const date = label.length === 7 ? parseISO(label + "-01") : parseISO(label);
    const formattedDate = format(date, "dd/MM/yy");

    return (
      <div className="custom-tooltip bg-white border px-3 py-2 text-sm">
        <p className="label">{`Semaine du ${formattedDate}`}</p>
        <p className="count text-xs mt-1 font-medium text-slate-500">{`${payload[0].value} essais réalisés`}</p>
      </div>
    );
  }

  return null;
};

const groupDataByDate = (
  data: ExerciseAttempt[],
  interval: "day" | "week" | "month",
) => {
  if (data.length === 0) return [];

  // Group the attempts by the appropriate date interval.
  const groupedData: { [key: string]: number } = {};

  data.forEach((attempt) => {
    let dateKey: string = "";
    if (interval === "day") {
      dateKey = format(startOfDay(attempt.at), "yyyy-MM-dd");
    } else if (interval === "week") {
      dateKey = format(startOfWeek(attempt.at), "yyyy-MM-dd");
    } else if (interval === "month") {
      dateKey = format(startOfMonth(attempt.at), "yyyy-MM");
    }

    groupedData[dateKey] = (groupedData[dateKey] || 0) + 1;
  });

  // Determine the overall date range based on the attempts.
  const dates = data.map((attempt) => {
    if (interval === "day") return startOfDay(attempt.at);
    if (interval === "week") return startOfWeek(attempt.at);
    return startOfMonth(attempt.at);
  });

  const minDate = new Date(dates[0].getTime());
  const maxDate = new Date();
  // const maxDate = new Date(dates[dates.length - 1].getTime());

  // Iterate over every interval between minDate and maxDate.
  const result: { date: string; count: number }[] = [];
  let currentDate = minDate;

  while (currentDate <= maxDate) {
    let key: string = "";
    if (interval === "day") {
      key = format(currentDate, "yyyy-MM-dd");
      currentDate = addDays(currentDate, 1);
    } else if (interval === "week") {
      key = format(currentDate, "yyyy-MM-dd");
      currentDate = addWeeks(currentDate, 1);
    } else if (interval === "month") {
      key = format(currentDate, "yyyy-MM");
      currentDate = addMonths(currentDate, 1);
    }
    result.push({
      date: key,
      count: groupedData[key] || 0,
    });
  }

  return result;
};

export const WorkProgress: FC = () => {
  const { settings, saveSettings } = useSettings();
  const { attempts } = useAttempts();

  const [interval, setInterval] = useState<"day" | "week" | "month">("week");

  const chartData = useMemo(
    () =>
      groupDataByDate(
        attempts.filter(
          (a) =>
            (settings?.display_from_date
              ? a.at >= new Date(settings?.display_from_date ?? "")
              : true) &&
            (settings?.current_group
              ? settings?.current_group?.exercises.includes(a.name)
              : true),
        ),
        interval,
      ),
    [attempts, interval, settings?.current_group, settings?.display_from_date],
  );

  const formatXAxis = (tickItem: string) => {
    // For "month" intervals, append "-01" to create a valid date string.
    const date =
      tickItem.length === 7 ? parseISO(tickItem + "-01") : parseISO(tickItem);

    return format(date, "dd/MM/yy");
  };

  if (settings?.hidden_elements?.includes("data_overall_progression")) {
    return null;
  }

  return (
    <div className="hidden sm:block relative -mt-4 -mb-8">
      <div className="absolute z-50 right-0">
        <Button
          size="xs"
          variant="ghost"
          onClick={() =>
            saveSettings({
              hidden_elements: [
                ...(settings?.hidden_elements ?? []),
                "data_overall_progression",
              ],
            })
          }
        >
          Masquer
        </Button>
      </div>
      <ResponsiveContainer width="100%" height={80}>
        <BarChart data={chartData} barCategoryGap="10%" barGap={0}>
          <XAxis
            dataKey="date"
            axisLine={false}
            interval="preserveStartEnd"
            tick={{ fill: colors.slate[400], fontSize: 9 }}
            tickLine={false}
            tickFormatter={formatXAxis}
            hide={true}
          />
          <YAxis hide={true} domain={["dataMin", "dataMax"]} />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Bar dataKey="count" fill={colors.slate[200]} radius={4} />
        </BarChart>
      </ResponsiveContainer>
      <div className="hidden w-[156px]">
        <Select
          value={interval}
          onValueChange={(interval) =>
            setInterval(interval as "day" | "week" | "month")
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Choisir une période…" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="day">Semaine</SelectItem>
            <SelectItem value="week">Semaine</SelectItem>
            <SelectItem value="month">Mois</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

import { FC } from "react";

import FadeContent from "~/components/blocks/Animations/FadeContent/FadeContent.tsx";
import { LinkDonate } from "~/components/LinkDonate.tsx";

interface SplashScreenDonateProps {
  remainingTime: number;
}

export const SplashScreenDonate: FC<SplashScreenDonateProps> = ({
  remainingTime,
}) => (
  <FadeContent
    blur={true}
    duration={500}
    className="w-full h-full flex flex-col items-center justify-center"
  >
    <div className="flex flex-col items-center justify-center z-10 bg-white py-6 sm:py-8 rounded-lg shadow-lg border-2 border-blue-600">
      <div className="flex items-center text-xl sm:text-2xl text-blue-600 font-semibold gap-4">
        <p>Soutenir KD Tools</p>
      </div>
      <div className="mt-4 flex flex-col gap-3 items-center max-w-2xl text-center text-balance">
        <p>
          Aujourd'hui KD Tools est utilisé par plus de 500 personnes,{" "}
          <strong className="font-semibold">dont vous !</strong>
        </p>
        <p>
          Si vous appréciez les outils proposés et que vous le pouvez, n'hésitez
          pas à{" "}
          <LinkDonate wrapperClassname="font-semibold">faire un don</LinkDonate>{" "}
          pour assurer la pérennité du projet.
        </p>
      </div>
      <p className="mt-10 text-xs text-center text-balance  max-w-md text-slate-500">
        Ce message disparaitra dans{" "}
        <span className="font-semibold">{remainingTime} seconde(s)</span>.
      </p>
      <p className="mt-1 text-xs text-center text-balance max-w-md text-slate-500">
        <LinkDonate wrapperClassname="font-semibold ">Faire un don</LinkDonate>{" "}
        pour ne plus l'afficher.
      </p>
    </div>
  </FadeContent>
);

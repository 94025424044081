import { clsx } from "clsx";
import { isSameDay } from "date-fns";
import { debounce } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { AdminBlock } from "~/routes/admin/components/AdminBlock.tsx";
import { AdminDrawerUser } from "~/routes/admin/components/AdminDrawerUser.tsx";
import { AdminHeaderPanel } from "~/routes/admin/components/AdminHeaderPanel.tsx";
import { AdminImport } from "~/routes/admin/components/AdminImport.tsx";
import { AdminUser } from "~/routes/admin/components/AdminUser.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";
import { LastImport } from "~/types/LastImport.ts";
import { LastScore } from "~/types/LastScore.ts";
import { TopAttempt } from "~/types/TopAttempt.ts";
import { TopScore } from "~/types/TopScore.ts";
import { formatDate } from "~/utils/formatDate.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

const AdminWithProviders: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [openUserId, setOpenUserId] = useState<string | null>(null);

  const [query, setQuery] = useState("");
  const [queryUsers, setQueryUsers] = useState<Tables<"profiles">[] | null>(
    null,
  );

  const [userCount, setUserCount] = useState<number | null>(null);
  const [userSuiviCount, setUserSuiviCount] = useState<number | null>(null);
  const [eplTestUsersCount, setEplTestUsersCount] = useState<number | null>(
    null,
  );
  const [scoresCount, setScoresCount] = useState<number | null>(null);
  const [donationsCount, setDonationsCount] = useState<number | null>(null);

  const [lastUsers, setLastUsers] = useState<Tables<"profiles">[] | null>(null);
  const [topAttempts, setTopAttempts] = useState<TopAttempt[] | null>(null);
  const [topScores, setTopScores] = useState<TopScore[] | null>(null);
  const [lastScores, setLastScores] = useState<LastScore[] | null>(null);
  const [lastImports, setLastImports] = useState<LastImport[] | null>(null);

  const getLastUsers = useCallback((limit = 5) => {
    supabase
      .from("profiles")
      .select()
      .order("created_at", { ascending: false })
      .limit(limit)
      .then(({ data }) => setLastUsers(data ?? []));
  }, []);

  const getTopAttempts = useCallback((limit = 5) => {
    supabase
      .from("views_top_attempts")
      .select()
      .limit(limit)
      .then(({ data }) => setTopAttempts((data as TopAttempt[]) ?? []));
  }, []);

  const getTopScores = useCallback((limit = 5) => {
    supabase
      .from("views_top_scores")
      .select()
      .limit(limit)
      .then(({ data }) => setTopScores((data as TopScore[]) ?? []));
  }, []);

  const getLastImports = useCallback((limit = 20) => {
    supabase
      .from("views_last_imports")
      .select()
      .gt("attempts_added_sum", 0)
      .limit(limit)
      .order("last_at", { ascending: false })
      .then(({ data }) => setLastImports((data as LastImport[]) ?? []));
  }, []);

  const getLastScores = useCallback((limit = 20) => {
    supabase
      .from("views_last_scores")
      .select()
      .limit(limit)
      .then(({ data }) => setLastScores((data as LastScore[]) ?? []));
  }, []);

  useEffect(() => {
    // Get All Users count…
    supabase
      .from("profiles")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setUserCount(count ?? 0));

    // Get Suivi Users count…
    supabase
      .from("suivi_settings")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setUserSuiviCount(count ?? 0));

    supabase
      .from("suivi_settings")
      .select("*", { count: "exact", head: true })
      .not("epl_api_key", "is", null)
      .then(({ count }) => setEplTestUsersCount(count ?? 0));

    // Get Scores count…
    supabase
      .from("scores")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setScoresCount(count ?? 0));

    // Get Donations count…
    supabase
      .from("donations")
      .select("*", { count: "exact", head: true })
      .then(({ count }) => setDonationsCount(count ?? 0));

    // Get Last Users…
    getLastUsers();

    // Get Top Attempts…
    getTopAttempts();

    // Get Top Scores…
    getTopScores();

    // Get Last Scores…
    getLastScores();

    // Get Last Imports…
    getLastImports();
  }, []);

  useEffect(() => {
    if (
      searchParams.has("uid") &&
      (!openUserId || openUserId !== searchParams.get("uid"))
    ) {
      setOpenUserId(searchParams.get("uid"));
    }

    if (!searchParams.has("uid") && !!openUserId) {
      setOpenUserId(null);
    }
  }, [openUserId, searchParams]);

  const debouncedFetchResults = useCallback(
    debounce((value: string) => {
      if (value.trim()) {
        void supabase
          .from("profiles")
          .select()
          .or(`email.ilike.%${value}%,discord_username.ilike.%${value}%`)
          .then(({ data }) => setQueryUsers(data));
      } else {
        setQueryUsers([]);
      }
    }, 500),
    [],
  );

  const handleOnSearchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    debouncedFetchResults(e.target.value);
  };

  return (
    <>
      <div className="sm:px-4 flex flex-col gap-4 sm:gap-6">
        <div>
          <Input
            type="search"
            value={query}
            onChange={handleOnSearchUser}
            placeholder="Rechercher un utilisateur…"
            className="h-8 sm:h-12 sm:text-lg"
          />
          {query.trim().length > 0 && (queryUsers?.length ?? 0) > 0 && (
            <ul className="mt-2.5 ml-2 space-y-0.5">
              {queryUsers?.map((queryUser) => (
                <li key={`search-${queryUser.user_id}`}>
                  <AdminUser {...queryUser} />
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 sm:gap-6">
          <AdminHeaderPanel title="Utilisateurs" value={userCount} />
          <AdminHeaderPanel
            title="Utilisateurs Suivi"
            value={userSuiviCount}
            className="hidden sm:block"
          />
          <AdminHeaderPanel
            title="Utilisateurs Suivi EPLtest"
            className="hidden sm:block"
            value={eplTestUsersCount}
          />
          <AdminHeaderPanel
            title="Essais KD Tools"
            value={scoresCount}
            className="hidden sm:block"
          />
          <AdminHeaderPanel
            title="Dons"
            value={donationsCount}
            className="hidden sm:block"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
          <AdminBlock title="Derniers Inscrits">
            <table className="w-full table-fixed">
              <tbody>
                {lastUsers?.map((user) => (
                  <tr
                    key={user.user_id}
                    className={clsx(
                      isSameDay(new Date(user.created_at), new Date())
                        ? "font-semibold bg-yellow-50"
                        : "even:bg-slate-50",
                    )}
                  >
                    <td className="text-sm px-2 py-1 w-7/12">
                      <p className="truncate">
                        <AdminUser {...user} />
                      </p>
                    </td>
                    <td className="text-right tabular-nums text-sm px-2">
                      <p className="">{formatDate(user.created_at)}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center py-1">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => getLastUsers((lastUsers?.length ?? 5) + 5)}
              >
                Voir plus
              </Button>
            </div>
          </AdminBlock>
          <AdminBlock
            title="Top Essais (Pilotest et EPLTest)"
            className="hidden sm:block"
          >
            <table className="w-full">
              <tbody>
                {topAttempts?.map((score, idx) => (
                  <tr key={`score-${idx}`} className={clsx("even:bg-slate-50")}>
                    <td className="text-right text-xs tabular-nums">
                      {idx + 1}
                    </td>
                    <td className="text-sm px-2 py-1">
                      <AdminUser {...score} />
                    </td>
                    <td className="text-right tabular-nums font-mono text-sm px-2">
                      {formatNumber(score.count)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center py-1">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => getTopAttempts((topAttempts?.length ?? 5) + 5)}
              >
                Voir plus
              </Button>
            </div>
          </AdminBlock>
          <AdminBlock title="Top Scores" className="hidden sm:block">
            <table className="w-full">
              <tbody>
                {topScores?.map((score, idx) => (
                  <tr key={`top-score-${idx}`} className="even:bg-slate-50">
                    <td className="text-right text-xs tabular-nums">
                      {idx + 1}
                    </td>
                    <td className="text-sm px-2 py-1">
                      <AdminUser {...score} />
                    </td>
                    <td className="text-right tabular-nums font-mono text-sm px-2">
                      {formatNumber(score.count)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center py-1">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => getTopScores((topScores?.length ?? 5) + 5)}
              >
                Voir plus
              </Button>
            </div>
          </AdminBlock>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <AdminBlock title="Derniers mports">
            <table className="w-full table-fixed">
              <tbody>
                {lastImports?.map((anImport, idx) => (
                  <tr
                    key={`import-${idx}`}
                    className={clsx(
                      isSameDay(new Date(anImport.last_at), new Date())
                        ? "bg-yellow-50"
                        : "even:bg-slate-50",
                    )}
                  >
                    <td
                      className={clsx("text-right tabular-nums text-sm px-2")}
                    >
                      {formatDate(anImport.last_at, "date")}
                    </td>
                    <td className="px-2">
                      <AdminImport source={anImport.source} />
                    </td>
                    <td className="text-sm px-2 py-1 w-1/2">
                      <p className="truncate">
                        <AdminUser {...anImport} />
                      </p>
                    </td>
                    <td className="px-2 text-sm text-right font-mono tabular-nums">
                      +{anImport.attempts_added_sum}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center py-1">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => getLastImports((lastImports?.length ?? 20) + 10)}
              >
                Voir plus
              </Button>
            </div>
          </AdminBlock>
          <AdminBlock title="Derniers Scores">
            <table className="w-full table-fixed">
              <tbody>
                {lastScores?.map((score, idx) => (
                  <tr
                    key={`last-score-${idx}`}
                    className={clsx(
                      isSameDay(new Date(score.at), new Date())
                        ? "bg-yellow-50"
                        : "even:bg-slate-50",
                    )}
                  >
                    <td className="text-right tabular-nums text-sm px-2">
                      {formatDate(score.at, "date")}
                    </td>
                    <td className="text-sm px-2 py-1 w-5/12">
                      <p className="truncate">
                        <AdminUser {...score} />
                      </p>
                    </td>
                    <td className="px-2 text-sm w-3/12">
                      <p className="font-medium truncate">{score.exercise}</p>
                    </td>
                    <td className="px-2 text-sm text-right font-mono tabular-nums">
                      {score.count}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-center py-1">
              <Button
                size="xs"
                variant="ghost"
                onClick={() => getLastScores((lastScores?.length ?? 20) + 10)}
              >
                Voir plus
              </Button>
            </div>
          </AdminBlock>
        </div>
      </div>
      <AdminDrawerUser
        userId={openUserId}
        onDismiss={() => {
          searchParams.delete("uid");
          setSearchParams(searchParams);
          // setOpenUserId(null);
        }}
      />
    </>
  );
};

export const Admin = () => {
  const navigate = useNavigate();

  const { canAccess } = useCurrentUser();

  useEffect(() => {
    if (!canAccess(Role.Admin)) {
      return navigate("/");
    }
  }, [canAccess, navigate]);

  if (!canAccess(Role.Admin)) {
    return null;
  }

  return <AdminWithProviders />;
};

import { Turnstile } from "@marsidev/react-turnstile";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { ButtonLoading } from "~/components/ButtonLoading.tsx";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "~/components/ui/input-otp.tsx";
import LoginBackgroundImage from "~/imgs/login-bg.jpg";
import { supabase } from "~/supabase.ts";

export const LoginPage: FC = () => {
  const navigate = useNavigate();

  const [cfTurnstileStatus, setCfTurnstileStatus] = useState("pending");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const [showOtp, setShowOtp] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleLoginWithDiscord = async () => {
    await supabase.auth.signInWithOAuth({
      provider: "discord",
      options: {
        redirectTo: `${window.location.origin}`,
        queryParams: { prompt: "none" },
      },
    });
  };

  const handleLoginWithEmail = async (
    evt: React.FormEvent<HTMLFormElement>,
  ) => {
    evt.preventDefault();

    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${window.location.origin}`,
      },
    });

    if (error) {
      setShowError(true);
    } else {
      setShowOtp(true);
    }
  };

  useEffect(() => {
    if (otp.length !== 6) {
      return;
    }

    supabase.auth
      .verifyOtp({ email, token: otp, type: "email" })
      .then(({ data }) => {
        if (data.session) {
          navigate("/");
        }
      });
  }, [email, navigate, otp]);

  useEffectOnce(() => {
    supabase.auth.getSession().then(({ data }) => {
      if (data.session) {
        navigate("/");
      }
    });
  });

  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img src="/ico.png" className="w-20" />
            <h2 className="mt-8 text-2xl font-semibold leading-9 tracking-tight text-slate-900">
              Se connecter à KD Tools
            </h2>
            <p className="mt-1 leading-6 text-pretty text-slate-500 text-sm">
              Utilisez votre compte Discord ou une adresse e-mail pour vous
              connecter à KD Tools.
            </p>
          </div>
          <div className="mt-8">
            <div className="grid grid-cols-1 gap-4">
              <button
                onClick={handleLoginWithDiscord}
                className="flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-[#5865F2] hover:bg-[#414bb3] focus-visible:ring-transparent"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 -28.5 256 256"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                >
                  <g>
                    <path
                      d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                      fill="white"
                    />
                  </g>
                </svg>
                <span className="text-sm font-semibold leading-6">
                  Se connecter avec Discord
                </span>
              </button>
            </div>
            <div className="mt-10">
              <div className="relative">
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div className="w-full border-t border-slate-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-6 text-slate-400">ou</span>
                </div>
              </div>
              <form className="mt-8" onSubmit={handleLoginWithEmail}>
                {showError && (
                  <p className="mb-4 text-sm text-red-500 font-medium leading-6">
                    Une erreur est survenue… Veuillez réessayer dans quelques
                    instants.
                  </p>
                )}
                {showOtp ? (
                  <div>
                    <p className="text-sm text-slate-600 mb-4 leading-6">
                      Veuillez saisir le code unique envoyé à l'adresse "{email}
                      ".
                    </p>
                    <label className="block text-sm font-medium text-slate-900">
                      Code unique :
                    </label>
                    <div className="flex items-center mt-1.5">
                      <InputOTP
                        maxLength={6}
                        value={otp}
                        onChange={setOtp}
                        pattern={REGEXP_ONLY_DIGITS}
                        autoFocus={true}
                      >
                        <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                      </InputOTP>
                    </div>
                  </div>
                ) : (
                  <>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-slate-900"
                      >
                        Adresse e-mail :
                      </label>
                      <div className="mt-1.5">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="b.smith@airfrance.com"
                          required
                          autoComplete="email"
                          value={email}
                          onChange={(evt) => setEmail(evt.currentTarget.value)}
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <ButtonLoading
                        type="submit"
                        // loading={showLoading}
                        disabled={cfTurnstileStatus !== "solved"}
                      >
                        <span className="text-sm font-medium">
                          Se connecter par e-mail
                        </span>
                      </ButtonLoading>
                      <Turnstile
                        siteKey="0x4AAAAAAAiZjVEFwmXYrwE5"
                        onError={() => setCfTurnstileStatus("error")}
                        onExpire={() => setCfTurnstileStatus("expired")}
                        onSuccess={() => setCfTurnstileStatus("solved")}
                        className="mt-2"
                        options={{
                          size: "flexible",
                          theme: "light",
                          language: "fr",
                        }}
                      />
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          alt=""
          src={LoginBackgroundImage}
          className="absolute inset-0 h-full w-full object-cover object-right"
        />
        <div className="absolute w-full bottom-0 text-center text-xs py-4 space-y-1 text-[#394363]">
          <p className="font-medium">
            Crédit photo :{" "}
            <a
              className="underline hover:no-underline"
              href="https://tete-en-lair.fr"
              target="_blank"
            >
              tete-en-lair.fr
            </a>
          </p>
          <p className="italic">
            Vous prenez l'avion ? On vous prend en photo !
          </p>
        </div>
      </div>
    </div>
  );
};

import clsx from "clsx";
import { ShieldAlert } from "lucide-react";
import { FC, ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import { useInterval } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { SplashScreenDonate } from "~/components/SplashScreenDonate.tsx";
import { useMatchingRoute } from "~/hooks/useMatchingRoute.ts";
import { useSplashScreen } from "~/hooks/useSplashScreen.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";

interface WrapperProps {
  children: ReactNode;
  header?: ReactNode;
  extended?: boolean;
  full?: boolean;
  isLoading?: boolean;
  splash?: boolean;
}

const WrapperRestricted = () => (
  <div className="h-full flex items-center justify-center">
    <p className="text-center font-medium flex flex-col items-center justify-center space-y-2">
      <ShieldAlert className="text-red-500" size={52} />
      <span>Accès restreint</span>
    </p>
  </div>
);

export const Wrapper: FC<WrapperProps> = ({
  header,
  children,
  extended = false,
  full = false,
  isLoading = false,
  splash = true,
}) => {
  const { canAccess } = useCurrentUser();
  const matchingRoute = useMatchingRoute();
  const { showSplash, remainingTime } = useSplashScreen(splash);

  const [randomKey, setRandomKey] = useState(crypto.randomUUID());

  useInterval(
    () => setRandomKey(crypto.randomUUID()),
    document.getElementById("app-header-actions") === null ? 150 : null,
  );

  if (isLoading) {
    return <LayoutLoading />;
  }

  if (matchingRoute.requireRole && !canAccess(matchingRoute.requireRole)) {
    return <WrapperRestricted />;
  }

  const headerActionsElement = document.getElementById("app-header-actions");

  if (!headerActionsElement) {
    return null;
  }

  if (showSplash) {
    return <SplashScreenDonate remainingTime={remainingTime} />;
  }

  return (
    <div
      key={randomKey}
      className={clsx(
        "mx-auto w-full h-full md:px-4",
        full ? "lg:w-11/12" : extended ? "lg:w-10/12" : "lg:w-8/12",
      )}
    >
      <div className="mt-2 h-full">{children}</div>
      {header &&
        headerActionsElement &&
        createPortal(header, headerActionsElement)}
    </div>
  );
};

import { last } from "lodash";
import { UnlockIcon } from "lucide-react";
import { FC, Suspense, useEffect } from "react";
import { Outlet, useLocation, useMatches } from "react-router-dom";

import { ScrollTop } from "~/components/ScrollTop.tsx";
import { AppSidebar } from "~/components/ui/app-sidebar.tsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
} from "~/components/ui/breadcrumb.tsx";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "~/components/ui/sidebar.tsx";
import { Toaster } from "~/components/ui/sonner.tsx";
import { TooltipProvider } from "~/components/ui/tooltip.tsx";
import { useLatestVersionCheck } from "~/hooks/useLatestVersionCheck.tsx";
import { CurrentUserProvider } from "~/providers/CurrentUserProvider.tsx";
import { ScoresProvider } from "~/providers/ScoresProvider.tsx";
import { SupabaseProvider } from "~/providers/SupabaseProvider.tsx";
import {
  RouteHandle,
  RouteObjectWithPossibleHandle,
} from "~/types/RouteHandle.ts";

const Layout: FC = () => {
  const location = useLocation();
  const matchingRoutes = useMatches();

  useLatestVersionCheck();

  useEffect(() => {
    const lastMatchingRoute = last(
      matchingRoutes,
    ) as RouteObjectWithPossibleHandle;

    if (!lastMatchingRoute || !lastMatchingRoute?.handle?.title) {
      return;
    }

    document.title = `${lastMatchingRoute?.handle?.title} – KD Tools`;
  }, [location.pathname, matchingRoutes]);

  const routeData = last(matchingRoutes)?.handle as RouteHandle;

  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset>
        <header className="flex h-20 shrink-0 items-center justify-between gap-2">
          <div className="flex items-center gap-2 px-3 sm:px-6 shrink-0">
            <SidebarTrigger />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbPage className="text-xl sm:text-2xl font-semibold underline underline-offset-4 flex items-center gap-1 sm:gap-2.5">
                    {routeData.title ?? "KD Tools"}
                    {routeData.requireRole && (
                      <UnlockIcon className="w-5 h-5 text-red-500" />
                    )}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <div id="app-header-actions" className="w-full sm:pr-6"></div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

const LayoutWithProviders = () => (
  <SupabaseProvider>
    <CurrentUserProvider>
      <ScoresProvider>
        <TooltipProvider>
          <Toaster
            expand={true}
            visibleToasts={2}
            className="flex justify-center"
            toastOptions={{
              classNames: {
                title: "text-[14px] text-blue-500",
                icon: "text-blue-500",
                description: "text-slate-500",
              },
            }}
          />
          <Layout />
          <ScrollTop />
        </TooltipProvider>
      </ScoresProvider>
    </CurrentUserProvider>
  </SupabaseProvider>
);

export default LayoutWithProviders;

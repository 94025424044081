import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { keyBy } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useEffectOnce } from "react-use";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { Exercise } from "~/exercise.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

export type BasicScore = Pick<Tables<"scores">, "success" | "timing">;

interface ScoreOverallTimings {
  [Key: string]: ScoreOverallTiming;
}

interface ScoreOverallTiming {
  exercise: string;
  variant: string;
  median_timing: number;
  average_timing: number;
  count: number;
}

interface ScoresContextType {
  saveScore: (
    exercise: Exercise,
    variant: string,
    score: BasicScore,
  ) => Promise<PostgrestSingleResponse<null>>;
  getScoresForExercise: (exercise: Exercise) => Promise<
    {
      exercise: string;
      variant: string;
      success: boolean;
      timing: number;
      at: string;
    }[]
  >;
  getScoresCountForExercise: (exercise: Exercise) => Promise<number>;
  getScoresForVariant: (
    exercise: string,
    variant: string,
  ) => Promise<
    {
      exercise: string;
      variant: string;
      success: boolean;
      timing: number;
      at: string;
    }[]
  >;
  getExercisesTaken: () => Promise<string[]>;
  getOverallTimings: (
    exercise: string,
    variant: string,
  ) => ScoreOverallTiming | null;
}

const ScoresContext = createContext<ScoresContextType | undefined>(undefined);

export const useScores = () => {
  const context = useContext(ScoresContext);

  if (context === undefined) {
    throw new Error("useScores must be used within a ScoresProvider");
  }

  return context;
};

interface ScoresProviderProps {
  children: ReactNode;
}

export const ScoresProvider: React.FC<ScoresProviderProps> = ({ children }) => {
  const { user } = useCurrentUser();

  const [overallTimings, setOverallTimings] =
    useState<null | ScoreOverallTimings>(null);

  const saveScore = useCallback(
    async (exercise: Exercise, variant: string, score: BasicScore) =>
      await supabase.from("scores").insert({
        user_id: user.id,
        exercise,
        variant: variant.toLocaleLowerCase(),
        ...score,
        at: new Date().toISOString(),
      }),
    [user.id],
  );

  const getScoresForExercise = useCallback(
    async (exercise: Exercise) => {
      const { data } = await supabase
        .from("scores")
        .select("exercise, variant, success, timing, at")
        .eq("user_id", user.id)
        .eq("exercise", exercise)
        .lte("timing", 120)
        .order("at", { ascending: true });

      return data ?? [];
    },
    [user.id],
  );

  const getScoresCountForExercise = useCallback(
    async (exercise: Exercise) => {
      const { count } = await supabase
        .from("scores")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.id)
        .eq("exercise", exercise);

      return count ?? 0;
    },
    [user.id],
  );

  const getScoresForVariant = useCallback(
    async (exercise: string, variant: string) => {
      const { data } = await supabase
        .from("scores")
        .select("exercise, variant, success, timing, at")
        .eq("user_id", user.id)
        .eq("exercise", exercise)
        .eq("variant", variant)
        .lte("timing", 120)
        .order("at", { ascending: true });

      return data ?? [];
    },
    [user.id],
  );

  const getExercisesTaken = useCallback(async () => {
    const { data } = await supabase
      .from("scores")
      .select("exercise")
      .eq("user_id", user.id)
      .order("exercise", { ascending: true });

    const frequencyMap: Record<string, number> = {};

    if (!data) {
      return [];
    }

    for (const score of data) {
      const { exercise } = score;
      frequencyMap[exercise] = (frequencyMap[exercise] || 0) + 1;
    }

    return Object.keys(frequencyMap).sort(
      (a, b) => frequencyMap[b] - frequencyMap[a],
    );
  }, [user.id]);

  const fetchTimings = useCallback(async () => {
    const { data } = await supabase.rpc("public_get_scores_timings").select();

    return keyBy(data ?? [], (t) => `${t.exercise}:${t.variant}`);
  }, []);

  const getOverallTimings = useCallback(
    (exercise: string, variant: string) => {
      return (
        overallTimings?.[
          `${exercise.toLocaleLowerCase()}:${variant.toLocaleLowerCase()}`
        ] ?? null
      );
    },
    [overallTimings],
  );

  useEffectOnce(() => {
    fetchTimings().then(setOverallTimings);
  });

  if (overallTimings === null) {
    return <LayoutLoading />;
  }

  return (
    <ScoresContext.Provider
      value={{
        saveScore,
        getScoresForExercise,
        getScoresCountForExercise,
        getScoresForVariant,
        getExercisesTaken,
        getOverallTimings,
      }}
    >
      {children}
    </ScoresContext.Provider>
  );
};

import { User } from "@supabase/supabase-js";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useSupabase } from "~/providers/SupabaseProvider.tsx";
import { Role } from "~/role.ts";
import { supabase } from "~/supabase.ts";
import { Tables } from "~/supabase.types.ts";

interface UserWithProfile extends User {
  profile: Tables<"profiles">;
}

interface CurrentUserContextType {
  user: UserWithProfile;
  accessToken: string;
  canAccess: (role: Role) => boolean;
}

const CurrentUserContext = createContext<CurrentUserContextType | undefined>(
  undefined,
);

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);

  if (context === undefined) {
    throw new Error("useCurrentUser must be used within a CurrentUserProvider");
  }

  return context;
};

interface CurrentUserProviderProps {
  children: ReactNode;
}

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  children,
}) => {
  const { session } = useSupabase();

  const [profile, setProfile] = useState<Tables<"profiles"> | null>(null);
  const isLoadingProfile = useRef(false);

  useEffect(() => {
    if (
      !isLoadingProfile.current &&
      session?.user &&
      (!profile || profile.user_id !== session.user.id)
    ) {
      isLoadingProfile.current = true;

      supabase
        .from("profiles")
        .select()
        .eq("user_id", session?.user.id)
        .single()
        .then(({ data }) => {
          isLoadingProfile.current = false;
          setProfile(data ?? null);
        });
    }
  }, [profile, session?.user]);

  const canAccess = useCallback(
    (role: Role): boolean => {
      const roles = session?.user.app_metadata.roles;

      if (!roles || roles.length === 0) {
        return false;
      }

      if (roles.includes(Role.Admin)) {
        return true;
      }

      return roles.includes(role);
    },
    [session?.user.app_metadata],
  );

  if (!session?.user || !profile) {
    return <LayoutLoading />;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        user: {
          ...session.user,
          profile,
        },
        accessToken: session.access_token,
        canAccess,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

import { differenceInDays } from "date-fns";
import { useEffect, useState } from "react";

import { useTabState } from "~/hooks/useTabState.ts";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { DAYS_BEFORE_DONATION_SPLASH_SCREEN } from "~/utils/splash.ts";

const SPLASH_DURATION = 8_000;

export const useSplashScreen = (withSplashScreen = false) => {
  const { isTabActive, tabStateCurrent, tabStatePrevious } = useTabState();

  const {
    user: { created_at, profile },
  } = useCurrentUser();

  const shouldShowSplashScreen =
    withSplashScreen &&
    profile.last_donation_at === null &&
    differenceInDays(new Date(), new Date(created_at)) >
      DAYS_BEFORE_DONATION_SPLASH_SCREEN;

  const [showChildren, setShowChildren] = useState(!shouldShowSplashScreen);

  const [remainingTime, setRemainingTime] = useState(
    !shouldShowSplashScreen ? 0 : SPLASH_DURATION / 1000,
  );

  useEffect(() => {
    if (!shouldShowSplashScreen) {
      if (!showChildren) {
        setShowChildren(true);
      }

      return;
    }

    const intervalId = setInterval(() => {
      if (isTabActive) {
        setRemainingTime((prev) => {
          if (prev <= 1) {
            setShowChildren(true);
            return 0;
          }

          return prev - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [
    isTabActive,
    profile.discord_username,
    shouldShowSplashScreen,
    showChildren,
    withSplashScreen,
  ]);

  useEffect(() => {
    if (
      showChildren &&
      tabStatePrevious &&
      tabStatePrevious !== "visible" &&
      tabStateCurrent === "visible"
    ) {
      setShowChildren(!shouldShowSplashScreen);
      setRemainingTime(!shouldShowSplashScreen ? 0 : SPLASH_DURATION / 1000);
    }
  }, [shouldShowSplashScreen, showChildren, tabStateCurrent, tabStatePrevious]);

  return { showSplash: !showChildren, remainingTime };
};

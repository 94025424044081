import { AtSignIcon, EarthLockIcon, LogOutIcon } from "lucide-react";
import * as React from "react";
import { Link } from "react-router-dom";

import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "~/components/ui/sidebar";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { Role } from "~/role.ts";
import { supabase } from "~/supabase.ts";

export function NavSecondary({
  ...props
}: React.ComponentPropsWithoutRef<typeof SidebarGroup>) {
  const { canAccess } = useCurrentUser();

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  return (
    <SidebarGroup {...props}>
      <SidebarGroupContent>
        <SidebarMenu>
          {canAccess(Role.Admin) && (
            <SidebarMenuItem>
              <SidebarMenuButton asChild size="sm">
                <Link to="/jarvis">
                  <EarthLockIcon />
                  <span>Admin</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          )}
          <SidebarMenuItem>
            <SidebarMenuButton asChild size="sm">
              <a
                href="https://forms.fillout.com/t/vJdHp6jTEqus"
                data-umami-event="global:contact"
                target="_blank"
              >
                <AtSignIcon />
                <span>Contacter</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
          {/*<SidebarMenuItem>*/}
          {/*  <SidebarMenuButton asChild size="sm">*/}
          {/*    <a*/}
          {/*      href={donateLink}*/}
          {/*      data-umami-event="global:donate"*/}
          {/*      target="_blank"*/}
          {/*    >*/}
          {/*      <HeartHandshakeIcon />*/}
          {/*      <span>Faire un don</span>*/}
          {/*    </a>*/}
          {/*  </SidebarMenuButton>*/}
          {/*</SidebarMenuItem>*/}
          <SidebarMenuItem>
            <SidebarMenuButton
              variant="destructive"
              size="sm"
              onClick={handleLogout}
            >
              <LogOutIcon />
              <span>Se déconnecter</span>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem className="text-[11px] text-slate-400 leading-5 px-2 py-2">
            Réalisé par un candidat aux sélections Cadets Air France – Transavia
            et non affilié à Pilotest ou EPLtest.
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

import clsx from "clsx";
import {
  CrownIcon,
  HomeIcon,
  LightbulbIcon,
  TrendingUpIcon,
} from "lucide-react";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";

import { Logo } from "~/components/Logo.tsx";
import { NavGroup } from "~/components/ui/nav-group.tsx";
import { NavSecondary } from "~/components/ui/nav-secondary.tsx";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "~/components/ui/sidebar";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { psy0Routes, psy1Routes } from "~/router.tsx";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const location = useLocation();

  const {
    user: { profile },
  } = useCurrentUser();

  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <Link to="/" className="flex justify-center mt-2">
              <Logo className={clsx("w-16 h-16 drop-shadow")} />
              {profile.last_donation_at !== null && (
                <span
                  className="absolute ml-20 mt-1"
                  title="Merci pour votre don !"
                >
                  <CrownIcon className="w-4 text-amber-400" />
                </span>
              )}
            </Link>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            <SidebarMenuItem className="mt-1 mb-1">
              <SidebarMenuButton isActive={location.pathname === "/"} asChild>
                <Link to="/" className="font-medium text-[16px]">
                  <p className="inline-flex items-center gap-2">
                    <HomeIcon className="w-5 h-5" />
                    <span>Accueil</span>
                  </p>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarMenuItem className="mb-1">
              <SidebarMenuButton
                isActive={location.pathname.includes("/suivi")}
                asChild
              >
                <Link to="/suivi" className="font-medium text-[16px]">
                  <p className="inline-flex items-center gap-2">
                    <TrendingUpIcon className="w-5 h-5" />
                    <span>Suivi Pilotest & EPLtest</span>
                  </p>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton
                isActive={location.pathname.includes("/quizlet")}
                asChild
              >
                <Link to="/quizlet" className="font-medium text-[16px]">
                  <p className="inline-flex items-center gap-2">
                    <LightbulbIcon className="w-5 h-5" />
                    <span>Culture G</span>
                  </p>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroup>
        <p className="px-4 mt-6 mb-2 font-medium uppercase text-[13px] text-slate-600 tracking-wide">
          Outils Cadets AF-TO
        </p>
        <NavGroup name="PSY 0" routes={psy0Routes} />
        <NavGroup name="PSY 1" routes={psy1Routes} />
        <NavSecondary className="mt-auto pt-6" />
      </SidebarContent>
    </Sidebar>
  );
}

import { useAtom } from "jotai";
import { ChevronRight, UnlockIcon } from "lucide-react";
import { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { hiddenNavGroupsAtom } from "~/atoms/hiddenNavGroupsAtom.ts";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/ui/collapsible.tsx";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "~/components/ui/sidebar";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { RouteObjectWithHandle } from "~/types/RouteHandle.ts";

interface NavGroupProps {
  name: string;
  routes: RouteObjectWithHandle[];
}

export const NavGroup: FC<NavGroupProps> = ({ name, routes }) => {
  const location = useLocation();

  const { canAccess } = useCurrentUser();

  const [hiddenNavGroups, setHiddenNavGroups] = useAtom(hiddenNavGroupsAtom);

  const authenticatedRoutes = useMemo(
    () =>
      routes.filter(
        (route) =>
          !route.handle.requireRole || canAccess(route.handle.requireRole),
      ),
    [canAccess, routes],
  );

  return (
    <Collapsible
      title={name}
      className="group/collapsible"
      open={!hiddenNavGroups.includes(name)}
      onOpenChange={(open) =>
        setHiddenNavGroups(
          open
            ? hiddenNavGroups.filter((group) => group !== name)
            : [...hiddenNavGroups, name],
        )
      }
    >
      <SidebarGroup>
        <SidebarGroupLabel
          asChild
          className="group/label text-xs tracking-widee uppercase text-slate-500 hover:bg-slate-100 hover:text-sidebar-accent-foreground"
        >
          <CollapsibleTrigger>
            {name}
            <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
          </CollapsibleTrigger>
        </SidebarGroupLabel>
        <CollapsibleContent>
          <SidebarGroupContent>
            <SidebarMenu>
              {authenticatedRoutes.map((route) => (
                <SidebarMenuItem key={route.path}>
                  <SidebarMenuButton
                    isActive={location.pathname === route.path}
                    asChild
                  >
                    <Link
                      to={route.path ?? ""}
                      className="font-medium flex justify-between"
                    >
                      <p className="inline-flex items-center gap-2">
                        {route.handle.icon && (
                          <route.handle.icon className="w-4 h-4" />
                        )}
                        <span>{route.handle.title}</span>
                      </p>
                      <div className="flex items-center gap-2">
                        {route.handle.requireRole && (
                          <UnlockIcon
                            className="text-slate-500 w-3.5 h-3.5"
                            strokeWidth={1.5}
                          />
                        )}
                      </div>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </CollapsibleContent>
      </SidebarGroup>
    </Collapsible>
  );
};

import { differenceInDays, isSameDay } from "date-fns";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LayoutLoading } from "~/components/LayoutLoading.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { supabase } from "~/supabase.ts";
import { Enums, Tables } from "~/supabase.types.ts";
import { DAYS_BEFORE_DONATION_SPLASH_SCREEN } from "~/utils/splash.ts";

type PartialImport = Pick<
  Tables<"suivi_imports">,
  "provider" | "attempts_added" | "at" | "source"
>;

interface ImportsContextType {
  imports: PartialImport[];
  importsFromTodayWithNewAttempts: PartialImport[];
  canImportAttempts: boolean;
  saveImport: (
    source: Enums<"import_source">,
    provider: Enums<"suivi_provider">,
    newAttemptsCount?: number,
  ) => Promise<void>;
}

const ImportsContext = createContext<ImportsContextType | undefined>(undefined);

export const useImports = () => {
  const context = useContext(ImportsContext);

  if (context === undefined) {
    throw new Error("useImports must be used within an ImportsProvider");
  }

  return context;
};

interface ImportsProviderProps {
  children: ReactNode;
}

export const ImportsProvider: React.FC<ImportsProviderProps> = ({
  children,
}) => {
  const { user } = useCurrentUser();

  const [imports, setImports] = useState<PartialImport[] | null>(null);

  const refreshImportsCallback = useCallback(
    async () =>
      supabase
        .from("suivi_imports")
        .select("provider, attempts_added, source, at")
        .eq("user_id", user.id)
        .order("at", { ascending: false })
        .then(({ data }) => setImports(data ?? [])),
    [user.id],
  );

  const saveImportCallback = useCallback(
    async (
      source: Enums<"import_source">,
      provider: Enums<"suivi_provider">,
      newAttemptsCount?: number,
    ) => {
      if (source === "auto_sync" && !newAttemptsCount) {
        const { data: existingEmptyAutoSync } = await supabase
          .from("suivi_imports")
          .select("id")
          .eq("user_id", user.id)
          .eq("provider", provider)
          .eq("source", "auto_sync")
          .eq("attempts_added", 0)
          .order("at", { ascending: false })
          .limit(1)
          .single();

        if (existingEmptyAutoSync) {
          return supabase
            .from("suivi_imports")
            .update({ at: new Date().toISOString() })
            .eq("id", existingEmptyAutoSync.id)
            .then(refreshImportsCallback);
        }
      }

      return supabase
        .from("suivi_imports")
        .upsert({
          user_id: user.id,
          provider,
          source,
          attempts_added: newAttemptsCount ?? 0,
        })
        .then(refreshImportsCallback);
    },
    [refreshImportsCallback, user.id],
  );

  useEffect(() => {
    refreshImportsCallback();
  }, [refreshImportsCallback]);

  const importsFromTodayWithNewAttempts = useMemo(
    () =>
      imports?.filter(
        (imp) => imp.attempts_added > 0 && isSameDay(imp.at, new Date()),
      ) ?? [],
    [imports],
  );

  const canImportAttempts = useMemo(
    () =>
      user.profile.last_donation_at !== null ||
      importsFromTodayWithNewAttempts.length <= 5 ||
      differenceInDays(new Date(), new Date(user.created_at)) <=
        DAYS_BEFORE_DONATION_SPLASH_SCREEN,
    [
      importsFromTodayWithNewAttempts.length,
      user.created_at,
      user.profile.last_donation_at,
    ],
  );

  if (imports === null) {
    return <LayoutLoading />;
  }

  return (
    <ImportsContext.Provider
      value={{
        imports,
        importsFromTodayWithNewAttempts,
        canImportAttempts,
        saveImport: saveImportCallback,
      }}
    >
      {children}
    </ImportsContext.Provider>
  );
};

import { ChartNoAxesCombinedIcon } from "lucide-react";
import { FC, useState } from "react";

import { DrawerScores, DrawerScoresProps } from "~/components/DrawerScores.tsx";
import { Button } from "~/components/ui/button.tsx";

interface ScoreButtonProps
  extends Omit<DrawerScoresProps, "open" | "onDismiss" | "title"> {
  label: string;
}

export const ScoreButton: FC<ScoreButtonProps> = ({ label, ...chartProps }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <Button
        className="hidden sm:flex"
        onClick={() => setShowDrawer(true)}
        variant="ghost"
        data-umami-event="scores:showScores"
        data-umami-event-exercise={chartProps.exercise}
        data-umami-event-variant={chartProps.variant}
      >
        <ChartNoAxesCombinedIcon className="w-5 h-5" strokeWidth={1.5} />
        <span className="ml-2.5">Mes Scores</span>
      </Button>
      <DrawerScores
        open={showDrawer}
        onDismiss={() => setShowDrawer(false)}
        title={`Scores pour "${label}"`}
        {...chartProps}
      />
    </>
  );
};

import { format, parse, parseISO, startOfMonth, startOfWeek } from "date-fns";
import { FC, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelList,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import colors from "tailwindcss/colors";

import { Badge } from "~/components/Badge.tsx";
import { Drawer, DrawerProps } from "~/components/Drawer.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Exercise } from "~/exercise.ts";
import { BasicScore, useScores } from "~/providers/ScoresProvider.tsx";
import { Tables } from "~/supabase.types.ts";
import { formatNumber } from "~/utils/formatNumber.ts";

type Period = "daily" | "weekly" | "monthly";

type AggregatedScore = {
  period: string;
  successCount: number;
  failureCount: number;
  avgTiming: number;
  minTiming: number;
  successRate: number;
};

export interface DrawerScoresProps extends Omit<DrawerProps, "children"> {
  exercise: Exercise;
  variant: string;
  filter?: (score: BasicScore) => boolean;
  withTimings?: boolean;
  withSuccesses?: boolean;
  withFailures?: boolean;
  withLegend?: boolean;
}

const getPeriod = (dateString: string, period: Period): string => {
  const date = parseISO(dateString);

  switch (period) {
    case "weekly":
      return format(startOfWeek(date, { weekStartsOn: 1 }), "yyyy-MM-dd"); // Week starts on Monday
    case "monthly":
      return format(startOfMonth(date), "yyyy-MM-dd");
    case "daily":
    default:
      return format(date, "yyyy-MM-dd");
  }
};

const processScores = (
  data: Pick<
    Tables<"scores">,
    "exercise" | "variant" | "success" | "timing" | "at"
  >[],
  period: Period = "weekly",
): AggregatedScore[] => {
  const aggregatedData: { [period: string]: AggregatedScore } = {};

  data.forEach((item) => {
    const periodKey = getPeriod(item.at, period);
    if (!aggregatedData[periodKey]) {
      aggregatedData[periodKey] = {
        period: periodKey,
        successCount: 0,
        failureCount: 0,
        avgTiming: 0,
        minTiming: Infinity,
        successRate: 0,
      };
    }

    if (item.success) {
      aggregatedData[periodKey].successCount += 1;
      aggregatedData[periodKey].avgTiming += item.timing;
      aggregatedData[periodKey].minTiming = Math.min(
        aggregatedData[periodKey].minTiming,
        item.timing,
      );
    } else {
      aggregatedData[periodKey].failureCount += 1;
    }
  });

  return Object.values(aggregatedData).map((item) => ({
    ...item,
    avgTiming: Math.round(
      item.avgTiming / (item.successCount + item.failureCount),
    ),
    minTiming: item.minTiming === Infinity ? 0 : item.minTiming,
    successRate: Math.round(
      (item.successCount / (item.successCount + item.failureCount)) * 100,
    ),
  }));
};

export const DrawerScores: FC<DrawerScoresProps> = ({
  exercise,
  variant,
  withTimings = true,
  withSuccesses = true,
  withFailures = true,
  withLegend = true,
  filter,
  ...drawerProps
}) => {
  const { getScoresForVariant, getOverallTimings } = useScores();

  const [scores, setScores] = useState<Partial<Tables<"scores">>[] | null>(
    null,
  );
  const [aggregatedScores, setAggregatedScores] = useState<
    AggregatedScore[] | null
  >(null);
  const [period, setPeriod] = useState<Period>("weekly");

  useEffect(() => {
    if (!drawerProps.open) {
      return;
    }

    getScoresForVariant(exercise, variant.toLocaleLowerCase()).then((res) => {
      setScores(res.slice().reverse());

      setAggregatedScores(
        processScores(filter ? res.filter(filter) : res, period),
      );
    });
  }, [
    exercise,
    filter,
    variant,
    period,
    getScoresForVariant,
    drawerProps.open,
  ]);

  const overallTiming = getOverallTimings(exercise, variant);

  return (
    <Drawer {...drawerProps}>
      {aggregatedScores === null ? (
        <p>Chargement…</p>
      ) : aggregatedScores.length === 0 ? (
        <p>Pas de résultat.</p>
      ) : (
        <>
          <div className="w-full">
            <div className="flex justify-end mb-2">
              <div className="flex items-center space-x-2">
                <p className="text-sm text-slate-400">Affichage</p>
                <Select
                  value={period}
                  onValueChange={(view) => setPeriod(view as Period)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choisir une période…" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="daily">Quotidien</SelectItem>
                    <SelectItem value="weekly">Hebdomadaire</SelectItem>
                    <SelectItem value="monthly">Mensuel</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={320}>
              <ComposedChart
                data={aggregatedScores}
                margin={{
                  top: 16,
                  right: 16,
                  left: -8,
                  bottom: 24,
                }}
              >
                <CartesianGrid stroke="#e5e7eb" vertical={false} />
                <XAxis
                  dataKey="period"
                  tickFormatter={(value) =>
                    format(parse(value, "yyyy-MM-dd", new Date()), "d MMM")
                  }
                  axisLine={false}
                  tickLine={false}
                  tick={{ fontSize: 12, fill: "#888" }}
                  dy={5}
                />
                {withTimings && (
                  <YAxis
                    yAxisId="left"
                    tickFormatter={(value) => `${value} s`}
                    axisLine={false}
                    tickLine={false}
                    tickCount={8}
                    tick={{ fontSize: 14, fill: "#888" }}
                    dx={-5}
                  />
                )}
                {(withSuccesses || withFailures) && (
                  <YAxis yAxisId="right" hide />
                )}
                <Tooltip />
                {withLegend && (
                  <Legend
                    wrapperStyle={{
                      paddingTop: "16px",
                      fontSize: "14px",
                    }}
                  />
                )}
                {withSuccesses && (
                  <Bar
                    yAxisId="right"
                    dataKey="successRate"
                    stackId="a"
                    fill="#82ca9d"
                    name="Réussites (%)"
                    barSize={32}
                    isAnimationActive={false}
                    radius={4}
                  >
                    <LabelList
                      dataKey="successRate"
                      position="insideTop"
                      style={{ fontSize: "10px", fill: "white" }}
                      formatter={(v: number) => `${v}%`}
                    />
                  </Bar>
                )}
                {withFailures && (
                  <Bar
                    yAxisId="right"
                    dataKey={(d) => 100 - d.successRate}
                    stackId="b"
                    fill="#ff6961"
                    name="Échecs (%)"
                    barSize={32}
                    isAnimationActive={false}
                    radius={4}
                  >
                    <LabelList<AggregatedScore & { value: number }>
                      dataKey={(d) => 100 - d.successRate}
                      position="insideTop"
                      style={{ fontSize: "10px", fill: "white" }}
                      formatter={(v: number) => `${v}%`}
                    />
                  </Bar>
                )}
                {withTimings && (
                  <>
                    <Line
                      yAxisId="left"
                      dataKey={(d) => (d.avgTiming > 0 ? d.avgTiming : null)}
                      type="bump"
                      stroke="#1d4ed8"
                      name="Temps moyen (s)"
                      strokeWidth={2.5}
                      dot={false}
                      connectNulls={true}
                      isAnimationActive={false}
                    >
                      <LabelList
                        position="top"
                        offset={8}
                        fontSize={10}
                        formatter={(v: number) => `${v} s`}
                        fill="#1d4ed8"
                      />
                    </Line>
                    <Line
                      yAxisId="left"
                      type="bump"
                      dataKey={(d) => (d.minTiming > 0 ? d.minTiming : null)}
                      stroke="#60a5fa"
                      name="Meilleur temps (s)"
                      strokeWidth={2}
                      dot={false}
                      connectNulls={true}
                      isAnimationActive={false}
                    >
                      <LabelList
                        position="bottom"
                        offset={8}
                        fontSize={10}
                        formatter={(v: number) => `${v} s`}
                        fill="#60a5fa"
                      />
                    </Line>
                  </>
                )}
                {withTimings && overallTiming && (
                  <ReferenceLine
                    label={
                      <Label
                        value={`Médiane KD Tools (${overallTiming.median_timing}s)`}
                        offset={8}
                        position="top"
                        fontSize={10}
                        fill={colors.amber[500]}
                      />
                    }
                    yAxisId="left"
                    y={overallTiming.median_timing}
                    stroke={colors.amber[500]}
                    strokeWidth={2}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          {withTimings && overallTiming?.median_timing && (
            <div className="-mt-4 text-center text-sm text-amber-500">
              <strong className="font-medium">
                Médiane KD Tools : {overallTiming?.median_timing}s
              </strong>{" "}
              (basée sur {formatNumber(overallTiming.count)} résultats)
            </div>
          )}
          <div className="mt-6">
            <h2 className="font-medium text-xl">
              Détails ({formatNumber(scores?.length)} essais)
            </h2>
            <div className="mt-2 mb-6 flow-root">
              <table className="min-w-full divide-y divide-slate-300">
                <tbody className="divide-y divide-slate-200 bg-white">
                  {scores?.map((score, idx) => (
                    <tr key={idx} className="even:bg-slate-50">
                      <td className="whitespace-nowrap px-2 py-1.5 text-xs font-medium text-slate-900">
                        <Badge type={score.success ? "success" : "error"}>
                          {score.success ? "Succès" : "Échec"}
                        </Badge>
                      </td>
                      <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-xs text-slate-500 sm:pl-0">
                        {format(parseISO(score.at!), "eeee d MMM y à HH:mm:ss")}
                      </td>
                      {withTimings && (
                        <td className="whitespace-nowrap px-2 py-1.5 text-xs text-slate-900 text-right">
                          {score.timing} sec
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};

import { shuffle } from "lodash";
import { FC, useEffect, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog.tsx";
import { Input } from "~/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Textarea } from "~/components/ui/textarea.tsx";
import { useCurrentUser } from "~/providers/CurrentUserProvider.tsx";
import { QUIZLET_CATEGORIES } from "~/routes/quizlet/Questions.tsx";
import { supabase } from "~/supabase.ts";
import { Enums } from "~/supabase.types.ts";

interface AddQuestionModalProps {
  open: boolean;
  onDismiss: () => void;
}

export const AddQuestionModal: FC<AddQuestionModalProps> = ({
  open,
  onDismiss,
}) => {
  const { user } = useCurrentUser();

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState(["", "", "", ""]);
  const [category, setCategory] = useState<Enums<"quizlet_category"> | null>(
    null,
  );

  useEffect(() => {
    if (open) {
      setQuestion("");
      setAnswers(["", "", "", ""]);
      setCategory(null);
    }
  }, [open]);

  const handleOnSave = (withReset = false) => {
    const correctAnswer = answers[0];

    supabase
      .from("quizlet_questions")
      .insert({
        category: category,
        question,
        answers: shuffle(answers),
        correct_answer: correctAnswer,
        author: user.profile.discord_username,
        answer_is_verified: true,
      })
      .then(() => {
        if (withReset) {
          setQuestion("");
          setAnswers(["", "", "", ""]);
          setCategory(null);
        } else {
          onDismiss();
        }
      });
  };

  const isDisabled =
    category === null ||
    question.trim().length === 0 ||
    answers.some((answer) => answer.trim().length === 0);

  return (
    <Dialog open={open} onOpenChange={onDismiss}>
      <DialogContent
        className="max-w-2xl"
        onOpenAutoFocus={(event) => event.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle className="inline-flex items-center gap-2">
            Ajouter une question de Culture G
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <p className="text-pretty">
            Vous pouvez ajouter des questions à la base de données KD Tools,
            celles-ci seront accessibles à tous les utilisateurs du site.
          </p>
          <div className="mt-2">
            <label className="block text-sm mb-1 font-medium">
              Catégorie :
            </label>
            <Select
              value={category ?? ""}
              onValueChange={(newCategory) =>
                setCategory(newCategory as Enums<"quizlet_category">)
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner une catégorie…" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(QUIZLET_CATEGORIES).map(
                  ([categoryKey, categoryValue]) => (
                    <SelectItem
                      key={`category-${categoryKey}`}
                      value={categoryKey}
                    >
                      {categoryValue}
                    </SelectItem>
                  ),
                )}
              </SelectContent>
            </Select>
          </div>
          <div className="mt-2">
            <label className="block text-sm mb-1 font-medium">Question :</label>
            <Textarea
              value={question}
              onChange={(evt) => setQuestion(evt.currentTarget.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-1">
            <div>
              <label className="block text-sm mb-1 font-medium">
                Réponse{" "}
                <span className="underline font-bold text-green-500">
                  CORRECTE
                </span>{" "}
                :
              </label>
              <Input
                value={answers[0]}
                onChange={(evt) =>
                  setAnswers((prevAnswers) =>
                    prevAnswers.map((answer, i) =>
                      i === 0 ? evt.currentTarget.value : answer,
                    ),
                  )
                }
              />
            </div>
            <div>
              <label className="block text-sm mb-1 font-medium">
                Réponse fausse 1 :
              </label>
              <Input
                value={answers[1]}
                onChange={(evt) =>
                  setAnswers((prevAnswers) =>
                    prevAnswers.map((answer, i) =>
                      i === 1 ? evt.currentTarget.value : answer,
                    ),
                  )
                }
              />
            </div>
            <div>
              <label className="block text-sm mb-1 font-medium">
                Réponse fausse 2 :
              </label>
              <Input
                value={answers[2]}
                onChange={(evt) =>
                  setAnswers((prevAnswers) =>
                    prevAnswers.map((answer, i) =>
                      i === 2 ? evt.currentTarget.value : answer,
                    ),
                  )
                }
              />
            </div>
            <div>
              <label className="block text-sm mb-1 font-medium">
                Réponse fausse 3:
              </label>
              <Input
                value={answers[3]}
                onChange={(evt) =>
                  setAnswers((prevAnswers) =>
                    prevAnswers.map((answer, i) =>
                      i === 3 ? evt.currentTarget.value : answer,
                    ),
                  )
                }
              />
            </div>
          </div>
        </div>
        <p className="text-xs text-slate-400 -my-2">
          Les réponses seront mélangées lors de l'enregistrement.
        </p>
        <div className="mt-2 flex gap-4 items-center">
          <Button
            className="flex-grow"
            variant="outline"
            disabled={isDisabled}
            onClick={() => handleOnSave(true)}
          >
            Enregistrer et ajouter une autre question
          </Button>
          <Button
            className="flex-grow"
            disabled={isDisabled}
            onClick={() => handleOnSave(false)}
          >
            Enregistrer et fermer
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

import clsx from "clsx";
import { useAtom } from "jotai/index";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useStopwatch } from "react-timer-hook";

import { showVirtualKeyboardAtom } from "~/atoms/showVirtualKeyboardAtom.ts";
import { TitleLabel } from "~/components/TitleLabel.tsx";
import { VirtualNumericKeyboard } from "~/components/VirtualNumericKeyboard.tsx";
import { Exercise } from "~/exercise.ts";
import { useScores } from "~/providers/ScoresProvider.tsx";
import { CM2_APP_MODE } from "~/routes/cm2/index.tsx";
import {
  IntervalWithDistance,
  IntervalWithDistanceAnswer,
} from "~/routes/cm2/types.ts";
import { createRandomIntervalWithDistance } from "~/routes/cm2/utils.ts";

export interface DistanceViewProps {
  mode: CM2_APP_MODE;
}

export const DistanceView: FC<DistanceViewProps> = ({ mode }) => {
  const { totalSeconds, reset: resetStopwatch } = useStopwatch({
    autoStart: true,
  });

  const { saveScore } = useScores();

  const [showVirtualKeyboard, setShowVirtualKeyboard] = useAtom(
    showVirtualKeyboardAtom,
  );

  const [currentElement, setCurrentElement] = useState<IntervalWithDistance>(
    createRandomIntervalWithDistance(),
  );

  const [answers, setAnswers] = useState<IntervalWithDistanceAnswer[]>([]);

  const fakeBarRef = useRef<HTMLInputElement | null>(null);
  const [fakeBarText, setFakeBarText] = useState("");

  useEffect(() => {
    setCurrentElement(createRandomIntervalWithDistance());
    setAnswers([]);
  }, [mode]);

  const handleOnClickNext = (shouldSaveAnswer = true) => {
    if (shouldSaveAnswer) {
      saveAnswer();
    }

    setCurrentElement(createRandomIntervalWithDistance());

    setFakeBarText("");

    if (!showVirtualKeyboard) {
      fakeBarRef.current?.focus();
    }
  };

  const handleOnTypeEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      handleOnClickNext();
    }

    if (evt.key === "Escape") {
      setAnswers([]);
      handleOnClickNext(false);
      resetStopwatch();
    }
  };

  const saveAnswer = () => {
    const answer: IntervalWithDistanceAnswer = {
      ...currentElement,
      success:
        mode === "distance"
          ? currentElement.distance.toString() === fakeBarText
          : currentElement.distanceModulo.toString() === fakeBarText,
      timing: totalSeconds,
    };

    setAnswers([...answers, answer]);

    saveScore(Exercise.CM2, mode, {
      success: answer.success,
      timing: answer.timing,
    });

    resetStopwatch();
  };

  return (
    <Fragment>
      {currentElement && (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <h2 className="mb-1 flex justify-between">
              <TitleLabel>
                Intervalle {mode === "distanceModulo" && "(MODULO 100)"}
              </TitleLabel>
              <span className="text-sm">
                {answers.filter((a) => a.success).length}/{answers.length}
              </span>
            </h2>
            <div className="overflow-hidden rounded-lg bg-slate-100 h-28">
              <div className="font-mono px-3 py-3 h-full flex justify-center items-center text-xl tabular-nums">
                {currentElement.interval}
              </div>
            </div>
            <div className="mt-2">
              <input
                ref={fakeBarRef}
                type="text"
                className={clsx(
                  "border-2 p-2 transition-colors duration-300 ease-in-out w-full rounded text-center text-2xl font-mono tracking-widest",
                  showVirtualKeyboard && "pointer-events-none",
                )}
                value={fakeBarText}
                onChange={(evt) => setFakeBarText(evt.currentTarget.value)}
                onKeyUp={handleOnTypeEnter}
              />
              <p className="text-right space-x-2">
                <button
                  className="text-xs underline hover:no-underline cursor-pointer mt-1 lg:hidden"
                  onClick={() => setShowVirtualKeyboard(!showVirtualKeyboard)}
                >
                  Afficher/masquer le clavier virtuel
                </button>
                <button
                  className="text-xs underline hover:no-underline cursor-pointer mt-1"
                  onClick={() => {
                    setAnswers([]);
                    handleOnClickNext(false);
                    resetStopwatch();
                  }}
                >
                  Réinitialiser
                </button>
              </p>
            </div>
            <ul className="mt-4 gap-1 flex flex-col-reverse mb-6">
              {answers.map((answer, idx) => (
                <li
                  key={`answer-${idx}`}
                  className={`rounded text-center py-1 font-mono text-sm ${
                    answer.success
                      ? "bg-green-100 text-green-800 "
                      : "bg-slate-100 text-slate-800"
                  }`}
                >
                  {answer.interval} ={" "}
                  {mode === "distance"
                    ? answer.distance
                    : answer.distanceModulo}{" "}
                  <span className="text-xs">({answer.timing} sec)</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {showVirtualKeyboard && (
        <VirtualNumericKeyboard
          onChange={setFakeBarText}
          onEnterPress={handleOnClickNext}
        />
      )}
    </Fragment>
  );
};

import { FC, useState } from "react";

import { HeaderWithMode } from "~/components/HeaderWithMode.tsx";
import { Input } from "~/components/Input.tsx";
import { ScoreButton } from "~/components/ScoreButton.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import { Exercise } from "~/exercise.ts";
import { LearningBaseView } from "~/routes/nombres-premiers/LearningBaseView.tsx";
import {
  getElements,
  getElementsToGuess,
  IndexedElement,
} from "~/routes/nombres-premiers/numbers.ts";
import { TrainingBaseView } from "~/routes/nombres-premiers/TrainingBaseView.tsx";

type APP_MODE = "LEARN" | "FREE_TRAINING" | "TIMED_TRAINING";

export const NombresPremiers: FC = () => {
  const [mode, setMode] = useState<APP_MODE>("FREE_TRAINING");

  const [elementsInput, setElementsInput] = useState(
    getElements()
      .map((el) => el.element)
      .join(","),
  );
  const [indexedElements, setIndexedElements] = useState<IndexedElement[]>(
    getElementsToGuess(100, elementsInput),
  );

  const handleOnChangeMode = (mode: string) => {
    setMode(mode as APP_MODE);
    setIndexedElements(getElementsToGuess(100, elementsInput));
  };

  return (
    <Wrapper
      header={
        <HeaderWithMode
          actions={
            <ScoreButton
              exercise={Exercise.NombresPremiers}
              variant="default"
              label="Nombres Premiers"
              withTimings={false}
              filter={() => true}
            />
          }
          mode={
            <Select value={mode} onValueChange={handleOnChangeMode}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner un mode…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="LEARN">Apprentissage</SelectItem>
                <SelectItem value="FREE_TRAINING">Entrainement</SelectItem>
                <SelectItem value="TIMED_TRAINING">Temps limité</SelectItem>
              </SelectContent>
            </Select>
          }
        />
      }
    >
      <>
        <div className="hidden mb-12">
          <Input
            name="elements"
            label="Valeurs à utiliser"
            value={elementsInput}
            onChange={(elements) => setElementsInput(elements)}
          />
        </div>
        {mode === "TIMED_TRAINING" ? (
          <TrainingBaseView indexedElements={indexedElements} withChrono />
        ) : mode === "FREE_TRAINING" ? (
          <TrainingBaseView indexedElements={indexedElements} />
        ) : (
          <LearningBaseView elements={elementsInput} />
        )}
      </>
    </Wrapper>
  );
};

import { clsx } from "clsx";
import { FC } from "react";

interface SelectionLogoProps {
  selection?: string | null;
  size?: "xs" | "sm" | "md";
  className?: string;
}

export const SelectionLogo: FC<SelectionLogoProps> = ({
  selection,
  size = "md",
  className = "",
}) => {
  if (!selection) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        size === "xs" ? "w-4 h-4" : size === "sm" ? "w-8 h-8" : "w-10 h-10",
      )}
    >
      <p>
        {selection === "main:cadets_af_to" || selection === "main:pro_af_to" ? (
          <img src="/selections/af_square.jpg" className="w-full" />
        ) : selection === "main:cadets_luxair" ? (
          <img src="/selections/luxair_square.jpg" className="w-full" />
        ) : selection === "main:epl" ? (
          <img src="/selections/enac_square.jpg" className="w-full" />
        ) : selection === "main:pro_hop" ? (
          <img src="/selections/hop_square.jpg" className="w-full" />
        ) : selection === "main:cadets_ezy" ? (
          <img src="/selections/ezy_square.jpg" className="w-full" />
        ) : null}
      </p>
    </div>
  );
};

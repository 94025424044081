import clsx from "clsx";
import { FC, ReactNode } from "react";

interface ButtonLoadingProps {
  type?: HTMLButtonElement["type"];
  loading?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

export const ButtonLoading: FC<ButtonLoadingProps> = ({
  type,
  loading,
  disabled,
  children,
}) => (
  <button
    type={type}
    className={clsx(
      "flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset",
      loading || disabled
        ? "bg-slate-100 text-slate-400 ring-slate-200 cursor-not-allowed"
        : "bg-white text-slate-900 ring-slate-300 hover:bg-slate-50",
      "focus-visible:ring-transparent",
    )}
    disabled={loading || disabled}
  >
    {children}
  </button>
);

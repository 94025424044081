import { FC, useState } from "react";

import { Button } from "~/components/ui/button.tsx";
import { Input } from "~/components/ui/input.tsx";
import { Tabs, TabsList, TabsTrigger } from "~/components/ui/tabs.tsx";
import { DrawerGroups } from "~/routes/suivi/components/DrawerGroups.tsx";
import { SelectionLogo } from "~/routes/suivi/components/SelectionLogo.tsx";
import { useGroups } from "~/routes/suivi/providers/GroupsProvider.tsx";
import { useSettings } from "~/routes/suivi/providers/SettingsProvider.tsx";

export const SUIVI_DEFAULT_GROUP = "INTERNAL|NO_GROUP";

export const Navigation: FC = () => {
  const { groups } = useGroups();
  const { settings, saveSettings } = useSettings();

  const [editGroups, setEditGroups] = useState(false);

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <SelectionLogo
            className="hidden sm:block"
            selection={settings?.selection}
          />
          <div className="flex items-center">
            <Tabs
              value={settings?.current_group_id ?? SUIVI_DEFAULT_GROUP}
              onValueChange={(newGroupId) =>
                saveSettings({
                  current_group_id:
                    newGroupId === SUIVI_DEFAULT_GROUP ? null : newGroupId,
                })
              }
            >
              <TabsList>
                <TabsTrigger value={SUIVI_DEFAULT_GROUP} className="px-6">
                  Tous
                </TabsTrigger>
                {groups.map((group) => (
                  <TabsTrigger
                    value={group.id}
                    key={`suivi-${group.id}`}
                    className="px-6"
                  >
                    {group.name}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
            <Button
              onClick={() => setEditGroups(!editGroups)}
              className="hidden sm:block ml-3"
              variant={groups.length > 0 ? "ghost" : undefined}
              size={groups.length > 0 ? "lg" : undefined}
            >
              <span>
                {groups.length === 0
                  ? "Ajouter un groupe…"
                  : "Modifier les groupes"}
              </span>
            </Button>
          </div>
        </div>
        <div className="hidden sm:flex items-center space-x-2">
          <label className="text-slate-500 text-sm">Afficher depuis :</label>
          <Input
            type="date"
            id="display-date"
            name="display-date"
            className="w-[148px]"
            value={settings?.display_from_date ?? ""}
            onFocus={(evt) => evt.currentTarget.showPicker()}
            onChange={(e) =>
              saveSettings({
                display_from_date:
                  e.currentTarget.value !== "" ? e.currentTarget.value : null,
              })
            }
          />
        </div>
      </div>
      <DrawerGroups open={editGroups} onDismiss={() => setEditGroups(false)} />
    </div>
  );
};

import { FC, useState } from "react";

import { HeaderWithMode } from "~/components/HeaderWithMode.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select.tsx";
import { Wrapper } from "~/components/Wrapper.tsx";
import {
  getElementsToGuess,
  IndexedElement,
} from "~/routes/calculs/elements.ts";
import { TrainingBaseView } from "~/routes/calculs/TrainingBaseView.tsx";

type APP_MODE = "FREE_TRAINING" | "TIMED_TRAINING";

export const Calculs: FC = () => {
  const [mode, setMode] = useState<APP_MODE>("FREE_TRAINING");

  const [indexedElements, setIndexedElements] = useState<IndexedElement[]>(
    getElementsToGuess(100),
  );

  const handleOnChangeMode = (mode: string) => {
    setMode(mode as APP_MODE);
    setIndexedElements(getElementsToGuess(100));
  };

  return (
    <Wrapper
      header={
        <HeaderWithMode
          mode={
            <Select value={mode} onValueChange={handleOnChangeMode}>
              <SelectTrigger>
                <SelectValue placeholder="Sélectionner un mode…" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="FREE_TRAINING">Entrainement</SelectItem>
                <SelectItem value="TIMED_TRAINING">Temps limité</SelectItem>
              </SelectContent>
            </Select>
          }
        />
      }
    >
      <>
        {mode === "TIMED_TRAINING" ? (
          <TrainingBaseView indexedElements={indexedElements} withChrono />
        ) : (
          <TrainingBaseView indexedElements={indexedElements} />
        )}
      </>
    </Wrapper>
  );
};
